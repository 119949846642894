import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { faUser, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Login } from 'src/app/models/login';
import { AppService } from 'src/app/services/app.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnChanges {
    @Input() login: Login;
        loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
    });
    submitted: boolean;
    faUser = faUser;
    faUnlockAlt = faUnlockAlt;
    loading = false;

    constructor(
        private appService: AppService,
        private loginService: LoginService,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.submitted = false;
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        } else {
            this.loading = true;
            this.loginService.getLogin(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(res => {
                this.router.navigate(['/dashboard']);
            }, error => {
                this.appService.handleError(error);
                this.loading = false;
            });
        }
    }

}
