import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { Simulation } from 'src/app/models/simulation';
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Token } from 'src/app/models/token';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-simulations-list',
    templateUrl: './simulations-list.component.html',
    styleUrls: ['./simulations-list.component.scss'],
    providers: [SimulationService]
})
export class SimulationsListComponent implements OnInit {
    simulations: Array<Simulation>;
    simulationsToDisplay: Array<Simulation>;
    colleaguesSimulations = false;
    faPencilAlt = faPencilAlt;
    faTrashAlt = faTrashAlt;
    @ViewChild('confirm', {static: true}) confirmModal: TemplateRef<any>;
    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private simulationService: SimulationService,
        private appService: AppService,
        private cookieService: CookieService,
        private modalService: NgbModal
    ) {
        this.simulations = [];
    }

    ngOnInit(): void {
        this.getSimulations();
    }

    getSimulations() {
        this.simulationService.getSimulations().subscribe(simulations => {
            this.simulations = simulations;
            this.checkSimulationsToDisplay(this.colleaguesSimulations);
        }, err => this.appService.handleError(err));
    }

    checkSimulationsToDisplay(cbColleaguesSimulations) {
        this.simulationsToDisplay = this.simulations;
        if (!cbColleaguesSimulations) {
            this.simulationsToDisplay = this.simulationsToDisplay.filter(sim => sim.user === this.appService.login.username);
        }
    }

    resumeSimulation(simulation: Simulation) {
        const token: Token = {
            id: '',
            type: 'RESUMEPMECALC',
            body: `{"BrokerNumber":${simulation.brokerId}, "Username":"${simulation.user}", "Language":"${this.appService.getLanguage()}", "Value":"${simulation.id}"}`,
            date: null,
            time: null
        };
        this.simulationService.createToken(token)
            .subscribe(resp => {
                if (environment.envName === 'dev') {
                    this.cookieService.set('RiskCalculator', resp.id, 0, '/');
                    window.location.href = environment.lrcURL;
                } else {
                    this.cookieService.set('RiskCalculator', resp.id, 0, '/', 'das.be');
                    window.location.href = environment.lrcURL;
                }
            }, err => this.appService.handleError(err));
    }

    deleteSimulation(simulation: Simulation) {
        this.modalService.open(this.confirmModal).result.then((result) => {
            if (result === 'confirm') {
                this.simulationService.deleteSimulation(simulation)
                    .subscribe(resp => {
                        this.getSimulations();
                    }, err => this.appService.handleError(err));
            }
        }, (reason) => {
        });
    }

}
