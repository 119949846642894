import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap, first } from 'rxjs/operators';
import { faSearch, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { Broker } from 'src/app/models/broker';
import { AppService } from 'src/app/services/app.service';
import { BrokerService } from 'src/app/services/broker.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss'],
    providers: [NgbActiveModal, NgbModal, BrokerService, LoginService]
})
export class AdminPanelComponent implements OnInit {
    @Output() reloadAs: EventEmitter<any> = new EventEmitter();
    faSearch = faSearch;
    faUserSecret = faUserSecret;
    brokers: Array<Broker>;
    loading = false;
    error: string | null;

    public searchForm = new FormGroup({
        searchField: new FormControl('')
    });
    private term$: Observable<string>;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private brokerService: BrokerService,
        public appService: AppService,
        private loginService: LoginService,
        private router: Router
    ) {
        this.term$ = this.searchForm.controls.searchField.valueChanges;
    }

    ngOnInit(): void {
        // this.searchAllBrokers();
        this.term$.pipe(
            filter((term) => {
                term = term.trim();
                if (!term.length) {
                    this.searchAllBrokers();
                }
                return term && term.length > 2;
            }),
            debounceTime(300),
            tap((term) => {
                this.error = null;
                this.loading = true;
            }),
            switchMap((term) =>
                this.brokerService.searchBrokers(term).pipe(
                    catchError((err) => {
                    this.loading = false;
                    this.error = err;
                    this.appService.handleError(err);
                    return of([]);
                })
                )
            )
            )
        .subscribe((brokers: Array<Broker>) => {
            this.loading = false;
            this.brokers = brokers;
        });
    }

    open(content) {
        this.modalService.open(content);
    }

    save() {
        this.reloadAs.emit('coucou');
        this.activeModal.close();
    }

    searchAllBrokers() {
        this.brokerService.getBrokers()
        .subscribe(brokers => {
            this.brokers = brokers;
        }, err => this.appService.handleError(err));
    }

    chooseBroker(broker: Broker) {
        this.loginService.loginAs(broker.logins[0])
            .subscribe(user => {
                this.appService.setLogin(user.login);
                this.appService.setToken(user.token);
                if (this.appService.login.lang === '1') {
                    this.appService.setLanguage('fr');
                } else {
                    this.appService.setLanguage('nl');
                }
                this.router.navigate(['/dashboard']);
            }, error => {
                this.appService.handleError(error);
                this.loading = false;
            });
    }

}
