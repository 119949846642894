import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { Token } from '../models/token';
import { Broker } from '../models/broker';

@Injectable({
    providedIn: 'root'
})
export class BrokerService {

    constructor(
        private appService: AppService,
        private http?: HttpClient
    ) {
    }

    public getBrokers(): Observable<Array<Broker>> {
        return this.http.get<Array<Broker>>(environment.api + `brokers`, this.appService.getOptions());
    }

    public searchBrokers(term: any): Observable<Array<Broker>> {
        return this.http.get<Array<Broker>>(environment.api + `brokers/search/${term}`, this.appService.getOptions());
    }
}
