export enum Vehicles {
    'v001' = 'vehicles.motorbike',
    'v002' = 'vehicles.scooter',
    'v003' = 'vehicles.car',
    'v005' = 'vehicles.caravane',
    'v006' = 'vehicles.trailer750',
    'v007' = 'vehicles.trailer',
    'v008' = 'vehicles.ambulance',
    'v011' = 'vehicles.truck',
    'v012' = 'vehicles.truck3.5',
    'v018' = 'vehicles.minibus',
    'v022' = 'vehicles.ceremonialCar',
    'v024' = 'vehicles.tractor',
    'v025' = 'vehicles.specialCar',
    'v039' = 'vehicles.bus',
    'v040' = 'vehicles.motorhome',
    'v041' = 'vehicles.oldtimer',
    'v029' = 'vehicles.allrisk',
    'v043' = 'vehicles.fromLRC.motorbike',
    'v044' = 'vehicles.fromLRC.trailer',
    'v045' = 'vehicles.fromLRC.car',
    'v046' = 'vehicles.fromLRC.truck',
    'v627' = 'vehicles.fromLRC.allrisk',
    'v628' = 'vehicles.fromLRC.allriskFree',
    'v551' = 'vehicles.specificBrokers.yacht',
    'v553' = 'vehicles.specificBrokers.yacht7',
    'v561' = 'vehicles.specificBrokers.yacht12',
    'v571' = 'vehicles.specificBrokers.yacht15',
    'v573' = 'vehicles.specificBrokers.speedboat',
    'v582' = 'vehicles.specificBrokers.yachtContract'
}
