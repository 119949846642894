import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    providers: [NgbActiveModal]
})
export class ConfirmComponent implements OnInit {
    @Input() message: string;
    @Input() c;
    @Input() d;
    closeResult: string;

    constructor(
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
    }

}
