import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { AppService } from 'src/app/services/app.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-simulations',
    templateUrl: './simulations.component.html',
    styleUrls: ['./simulations.component.scss']
})
export class SimulationsComponent implements OnInit {

    nbSimulations: number;
    constructor(
        private appService: AppService,
        private simulationService: SimulationService,
        public translate: TranslateService) {
        this.nbSimulations = 0;
    }

    ngOnInit() {
        this.simulationService.getSimulations()
            .subscribe(simulations => {
                this.nbSimulations = simulations.filter(sim => sim.user === this.appService.login.username).length;
            }, error => this.appService.handleError(error));
    }

}
