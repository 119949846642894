<div class="white-container">
    <h2>{{'claims.new' | translate}}</h2>
    <div class="center margin15" *ngFor="let question of questionToDisplay">
        <p>{{question.question}}</p>
        <div *ngIf="question.answer?.length === 0">
            <div *ngIf="question.possibleAnswers.length > 0">
                <div *ngFor="let answer of question.possibleAnswers" class="custom-control custom-control-inline custom-radio">
                    <input type="{{question.type}}" (change)="setAnswer($event.target.value)" id="{{answer}}{{question.id}}" value="{{answer}}" class="custom-control-input" name="{{question.id}}" />
                    <label *ngIf="question.type === 'radio'" for="{{answer}}{{question.id}}" class="custom-control-label">{{answer}}</label>
                </div>
            </div>
            <input *ngIf="question.possibleAnswers.length === 0" (change)="setAnswer($event.target.value)" type="{{question.type}}" class="form-control" />
        </div>
        <div *ngIf="question.answer?.length > 0">
            <div>{{question.answer}}</div>
        </div>
    </div>
    <div class="form-group center">
        <button type="button" class="btn btn-primary" (click)="save()" [class.disabled]="answerToSave === ''">next</button>
    </div>
</div>