<div class="container">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">{{'user.username' | translate}}</label>
            <fa-icon [icon]="faUser"></fa-icon> 
            <input type="text" class="form-control" [class.is-invalid]="f.username.errors && submitted" 
                id="username" formControlName="username" placeholder="{{'user.username' | translate}}">
        </div>
        <div class="form-group">
            <label for="password">{{'user.password' | translate}}</label>
            <fa-icon [icon]="faUnlockAlt"></fa-icon> 
            <input type="password" class="form-control" [class.is-invalid]="f.password.errors && submitted" 
                id="password" formControlName="password" placeholder="{{'user.password' | translate}}">
        </div>
        <div class="form-group center">
            <button [disabled]="loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login
            </button>
        </div>
        <p class="center"><a>{{'lost password' | translate}}</a></p>
    </form>
</div>
