import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomiciliatedPolicy } from '../models/domiciliated-policy';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class DomiciliatedPolicyService {

  constructor(
      private appService: AppService,
      private http?: HttpClient
    ) {
  }

  public getDomiciliatedPolicy(id: string): Observable<DomiciliatedPolicy> {
      return this.http.get<DomiciliatedPolicy>(environment.api + `domiciliated-policies/${id}`, this.appService.getOptions());
  }
}
