import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from '../models/login';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { Simulation } from '../models/simulation';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
    private appService: AppService,
    private http?: HttpClient) { }

    public getLogin(id: string, pw: string): Observable<{token: string, login: Login}> {
        const body = {
            username: id,
            password: pw
        };
        return this.http.post<{token: string, login: Login}>(environment.api + `logins`, body, this.appService.getOptions())
            .pipe(map(user => {
                this.appService.setLogin(user.login);
                this.appService.setToken(user.token);
                if (this.appService.login.lang === '1') {
                    this.appService.setLanguage('fr');
                } else {
                    this.appService.setLanguage('nl');
                }
                return user;
            }));
    }

    public loginFromToken(tokenId: string): Observable<{token: string, login: Login, body: object, simulation: Simulation}> {
        return this.http.post<{token: string, login: Login, body: object, simulation: Simulation}>(environment.api + `logins/token`, {tokenId: tokenId}, this.appService.getOptions());
    }

    public loginAs(login: Login): Observable<{token: string, login: Login}> {
        const body = {
            login: login
        };
        return this.http.post<{token: string, login: Login}>(environment.api + `logins/connectAs`, body, this.appService.getOptions());
    }
}
