import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Offer } from 'src/app/models/offer';
import { OfferService } from 'src/app/services/offer.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { Risk } from 'src/app/models/risk';

@Component({
    selector: 'app-offer-edit',
    templateUrl: './offer-edit.component.html',
    styleUrls: ['./offer-edit.component.scss'],
    providers: [OfferService]
})
export class OfferEditComponent implements OnInit {
    id: number;
    step: number;
    data: Offer;
    loadingPromise = new Promise((resolve, reject)  => {
        return '';
    });
    error0: boolean;
    error1: boolean;
    error2: boolean;
    calculAuto: boolean;
    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private offerService: OfferService,
        private appService: AppService
    ) {
        this.id = 0;
        this.step = 0;
        this.data = new Offer();
    }

    ngOnInit() {
        if (Number(this.route.snapshot.paramMap.get('id')) > 0) {
            this.id = Number(this.route.snapshot.paramMap.get('id'));
            this.getOfferRisks(this.id, {});
        }
        this.step = Number(this.route.snapshot.paramMap.get('step'));
    }

    getOfferRisks(id, data: any) {
        this.offerService.getOffer(id).subscribe((offer) => {
            this.data = offer;
            if (data.FINQ != null) {
                this.data.FINQ = data.FINQ;
                this.data.PRBQ = data.PRBQ;
                this.data.DEBQ = data.DEBQ;
            }
            this.loadingPromise = Promise.resolve(offer);
        });
    }

    changeStep(index: number, calcAuto = false) {
        this.step = index;
        this.calculAuto = calcAuto;

        this.loadingPromise.then((offer: Offer) => {
            switch (this.step) {
                case 2: // risks
                    if (this.testRisksDates(offer)) {
                        this.error2 = true;
                        return;
                    }

                    if (this.testIndependentRisks(offer)) {
                        this.error2 = true;
                        this.translate.get('errors.allrisk').subscribe((res: string) => {
                            alert(res);
                        });
                        return;
                    }

                    if (this.testFamilyRisks(offer)) {
                        return;
                    }

                    if (this.testVehiclesRisks(offer)) {
                        this.error2 = true;
                        return;
                    }

                    break;
                default:
                    break;
            }
        });

        if (this.id > 0) {
            this.router.navigate(['/production/offer/edit', this.id, this.step]);
        } else {
            this.router.navigate(['/production/offer/create', this.step]);
        }
    }

    dataChanged(type: string, data: any) {
        if (this.step + 1 === 3) {
            this.getOfferRisks(this.id, data);
        } else {
            this.data = data;
        }

        this.changeStep(this.step + 1);
    }

    testRisksDates(offer): boolean {
        if (offer.risks.length > 0) {
            let riskStartDateEqualContractStartDate = 0;
            let buildDueDate = offer.startDate.toString();
            buildDueDate = buildDueDate.substring(0, 4) + '-' +
                            offer.dueDate.toString().substring(0, 2) + '-' +
                            offer.dueDate.toString().substring(2, 4);

            const dueDateToDate = new Date(buildDueDate);

            offer.risks.forEach(risk => {
                if (risk.startDate < offer.startDate || risk.startDate >= dueDateToDate) {
                    alert(this.translate.get('errors.dueDate',
                        {dueDate: buildDueDate, description: risk.description}));
                    return true;
                }

                if (risk.startDate === offer.startDate) {
                    riskStartDateEqualContractStartDate++;
                }
            });

            if (riskStartDateEqualContractStartDate === 0) {
                this.translate.get('errors.startDate').subscribe((res: string) => {
                    alert(res);
                });

                return true;
            }
        }

        return false;
    }

    testIndependentRisks(offer): boolean {
        // On teste si le risque All Risk Indépendants (690) n'est pas utilisé avec le risque Après-Incendie (369)
        return offer.risks.some(risk => risk.riskCode === 690) && offer.risks.some(risk => risk.riskCode === 369);
    }

    testFamilyRisks(offer): boolean {
        // Les risques 'Economique' et 'Conflits' n'existent plus
        if (offer.risks.some(risk => risk.riskCode === 393 || risk.riskCode === 394 || risk.riskCode === 390)) {
            this.translate.get('errors.oldRisks').subscribe((res: string) => {
                alert(res);
                this.error2 = true;
            });
            return true;
        }

        // il existe au moins un risque Benefisc
        if (offer.risks.some(risk => risk.riskCode === 450 || risk.riskCode === 453 || risk.riskCode === 456)) {
            // l'utilisateur a indiqué qu'il souhaitait recevoir ses doc de manière digitale
            if (offer.digitalConsent) {
                // l'adresse email est vide
                if (offer.email === '') {
                    this.translate.get('errors.emailMissing').subscribe((res: string) => {
                        alert(res);
                        this.error0 = true;
                    });
                    return true;
                }
            }
        }

        // On teste si le risque Consommateur / Consommateur Benefisc / Conflit Benefisc
        // n'est pas utilisé avec le risque Après-Incendie usage Privé (368 + type = 1)
        if (offer.risks.some(risk =>
            (risk.riskCode === 368 && risk.type === 1) &&
            (
                (risk.riskCode === 393 && risk.riskCode === 394) ||
                (risk.riskCode === 398) ||
                (risk.riskCode === 390) ||
                (risk.riskCode === 450) ||
                (risk.riskCode === 453)
            ))) {

            this.translate.get('errors.combinationWithAfterFireRefused').subscribe((res: string) => {
                alert(res);
                this.error2 = true;
            });
            return true;
        }

        return false;
    }

    testVehiclesRisks(offer): boolean {
        let nbVehicles = 0; // nombre de véhicules
        let vehiclesInPackage = 0; // nombre de véhicules dans le package véhicule
        let yacht = 0; // yacht / bateau à voile
        let menueEmbarcation = 0;
        const listTypeVehiclePackage = [];

        offer.risks.forEach(risk => {
            if (risk.type > 400 && risk.type < 411) { // packages et bateaux
                nbVehicles++;

                if (Number(risk.package) === 1) {
                    vehiclesInPackage++;
                    listTypeVehiclePackage.push(risk.type);
                }

                if (risk.riskCode === 551 || risk.riskCode === 553 ||
                    risk.riskCode === 561 || risk.riskCode === 571 || risk.riskCode === 573) {
                    menueEmbarcation++;
                } else {
                    if (risk.riskCode === 582) {
                        yacht++;
                    }
                }
            }
        });

        // On teste s'il n'y a pas de types véhicules qu'on ne peut pas assurer seuls
        if (nbVehicles === 1) {
            if (offer.risks.some(risk => risk.riskCode === 5 || risk.riskCode === 6 ||
                                         risk.riskCode === 7 || risk.riskCode === 40 ||
                                         risk.riskCode === 41)) {
                this.translate.get('errors.singleVehicle').subscribe((res: string) => {
                    alert(res);
                });
                return true;
            }
        }

        // On teste s'il n'y a pas de package véhicule avec seulement un véhicule
        if (vehiclesInPackage === 1) {
            this.translate.get('errors.atLeast2Package').subscribe((res: string) => {
                alert(res);
            });
            return true;
        } else {
            // On teste s'il n'y a pas de package véhicule en combinaison des risques Famille - Indépendants
            // Alors on décoche package famille sur le risque véhicule (package = 2 = Non)
            if (vehiclesInPackage >= 2 && offer.risks.some(risk => risk.type === 101 || risk.type === 102 ||
                                                            risk.type === 103 || risk.type === 201 ||
                                                            risk.type === 202)) {
                let riskToUpdate: Risk;
                listTypeVehiclePackage.forEach(type => {
                    riskToUpdate = offer.risks.filter(risk => risk.type === type)[0];
                    const ind = offer.risks.indexOf(riskToUpdate);
                    riskToUpdate.package = 2;

                    this.offerService.updateRisk(type, offer.id, riskToUpdate).subscribe(risk => {
                        this.data.risks[ind] = risk;
                    }, error => this.appService.handleError(error));
                });
            }
        }

        if (offer.risks.some(risk => risk.plateNumber === '' && risk.type.toString().slice(0, 1) === '4')) {
            this.translate.get('errors.plateNumberEmpty').subscribe((res: string) => {
                alert(res);
            });
            return true;
        }

        return false;
    }

    checkStep3() {
        if (this.data.risks !== undefined && this.data.risks !== null) {
            return this.data.risks[0]?.netBonus > 0;
        } else {
            return false;
        }
    }
}
