
<div class="container white-container">
    <a (click)="changeStep(0)">
        <span [class.focus]="step === 0">1.{{"client data" | translate}}</span>
        <div class="block left" [class.filled]="data.id !== undefined && data.lastName !== '' && !error0" [class.error]="error0"></div>
    </a>
    <a (click)="changeStep(1)" [class.disabled]="data.id === undefined || data.lastName === ''">
        <span [class.focus]="step === 1">2.{{"contract data" | translate}}</span>
        <div class="block" [class.filled]="data.id !== undefined && data?.startDate !== null && !error1" [class.error]="error1"></div>
    </a>
    <a (click)="changeStep(2)" [class.disabled]="data.id === undefined || data.lastName === '' || data.startDate === null">
        <span [class.focus]="step === 2">3.{{"risks" | translate}}</span>
        <div class="block" [class.filled]="data.id !== undefined && data.risks?.length > 0 && !error2" [class.error]="error2"></div>
    </a>
    <a (click)="changeStep(2, true)" [class.disabled]="data.id === undefined || data.lastName === '' || data.startDate === null || data.risks?.length === 0 || data.risks[0]?.netBonus === 0">
        <span [class.focus]="step === 3">4.{{"price calculation" | translate}}</span>
        <div class="block" [class.filled]="checkStep3()"></div>
    </a>
    <a (click)="changeStep(4)" [class.no-event]="data.policyNumber === '' || data.policyNumber === undefined" [class.disabled]="data.policyNumber === '' || data.policyNumber === undefined">
        <i class="das-icon icon-contract" [class.focus]="step === 4"></i>
        <div class="block right" [class.filled]="data.policyNumber !== '' && data.policyNumber !== undefined"></div>
    </a>
</div>

<app-edit-client-data [offer]="data" [mode]="'offer'" (update)="dataChanged('client', $event)" *ngIf="step === 0"></app-edit-client-data>
<app-edit-contract-data [offer]="data" [mode]="'offer'" (update)="dataChanged('contract', $event)" *ngIf="step === 1"></app-edit-contract-data>
<app-edit-risk-data [offer]="data" [mode]="'offer'" [calcul]="calculAuto" (update)="dataChanged('risk', $event)" *ngIf="step === 2"></app-edit-risk-data>
<app-edit-bonus-calculation [offer]="data" [mode]="'offer'" (update)="dataChanged('bonus', $event)" *ngIf="step === 3"></app-edit-bonus-calculation>
<app-documents-download [offer]="data" [type]="'NA'" *ngIf="step === 4"></app-documents-download>