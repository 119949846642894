<div class="white-container with-progressbar" *ngIf="environment.isDas === true && (calcul === false || calcul === undefined)">
    <div class="risks">
        <a routerLink="/production/{{mode}}/risk/create/{{offer.id}}">
            <fa-icon [icon]="faCartPlus"></fa-icon>
            <span>{{'product.addRisk' | translate}}</span>
        </a>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>{{'product.effect' | translate}}</th>
                <th>{{'product.risk' | translate}}</th>
                <th>{{'product.plateNumber' | translate}}</th>
                <th *ngIf="mode === 'policy'">{{'amendments.state' | translate}}</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let risk of offer.risks; index as i">
                <td>
                    {{ risk.startDate | date:'dd/MM/yyyy' }}
                </td>
                <td>{{ risk.rate['description' + appService.getDescriptionLanguage()] }}</td>
                <td>{{ risk.plateNumber }}</td>
                <td *ngIf="mode === 'policy'">{{ 'amendments.' + risk.operation | translate }}</td>
                <td><a (click)="deleteRisk(risk)"><fa-icon [icon]="faTrashAlt"></fa-icon></a></td>
                <td><a [routerLink]="['/production/offer/risk/edit', risk.type, risk.offerId]"><fa-icon [icon]="faPencilAlt"></fa-icon></a></td>
            </tr>
        </tbody>
    </table>
</div>
<div class="center" *ngIf="environment.isDas === true && (calcul === false || calcul === undefined)">
    <button type="button" (click)="priceCalculation()" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'price calculation' | translate}}
    </button>
</div>