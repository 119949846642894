<div class="header">
    <div class="flex-item">
        <a routerLink="/dashboard" routerLinkActive="active"
            title="Terug naar de homepage"
            rel="noreferrer">
            <img alt="DAS" class="logo" src="/assets/img/logo.png" *ngIf="appService.isDas === true"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_fr.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'fr'"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_nl.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'nl'"/>
            <img alt="AP" class="logo" src="/assets/img/dvv_fr.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'fr'"/>
            <img alt="DVV" class="logo" src="/assets/img/dvv_nl.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'nl'"/>
        </a>
        <p *ngIf="!environment.isBelfius && !environment.isDvv">
            {{'legal expenses insurance' | translate}}
        </p>
    </div>
    <div class="flex-item" *ngIf="appService.isDas">
        <table>
            <tr>
                <td class="title">Login :</td>
                <td class="value">{{appService.login?.username}}</td>
            </tr>
            <tr>
                <td class="title">{{'productnb' | translate}} :</td>
                <td class="value">{{appService.login?.brokerId}}</td>
            </tr>
            <tr>
                <td class="title">Email :</td>
                <td class="value">{{appService.login?.email}}</td>
            </tr>
        </table>
    </div>
    <div class="flex-item">
        <a class="header-button" routerLink="/dashboard" routerLinkActive="active" title="Terug naar de homepage">
            <fa-icon [icon]="faHome"></fa-icon> 
        </a>
        <app-admin-panel *ngIf="this.appService.login != undefined && this.appService.login.username === 'AF00100003'" (reloadAs)="reloadDashboardAs($event)"></app-admin-panel>
        <a class="header-button" routerLink="/dashboard" routerLinkActive="active" title="">
            <fa-icon [icon]="faCog"></fa-icon> 
        </a>
        <a class="header-button" routerLink="/dashboard" routerLinkActive="active" title="">
            <fa-icon [icon]="faAddressBook"></fa-icon> 
        </a>
        <a class="header-button" (click)="logout()" routerLinkActive="active" title="">
            <fa-icon [icon]="faPowerOff"></fa-icon> 
        </a>
        <a class="header-button" (click)="appService.setLanguage('fr')" *ngIf="appService.getLanguage()!=='fr'">FR</a>
        <a class="header-button" (click)="appService.setLanguage('nl')" *ngIf="appService.getLanguage()!=='nl'">NL</a>
    </div>
</div>
