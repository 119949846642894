import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AmendmentService {

  constructor(
    private appService: AppService,
    private http?: HttpClient
  ) { }

  public amendmentInit(id: string): Observable<any> {
    return this.http.get<any>(environment.api + `amendments/init/${id}`, this.appService.getOptions());
  }

  public getAmendment(id: string, amendmentId: string): Observable<any> {
    return this.http.get<any>(environment.api + `amendments/${id}/version/${amendmentId}`, this.appService.getOptions());
  }
}
