<div class="white-container">
    <h2>{{'product.title' + type | translate}}</h2>
    <form *ngIf="risk != null && form" [formGroup]="form" id="riskForm" (ngSubmit)="onSubmit()">
        <div class="center">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-block" [class.active]="type === 'car'" *ngIf="action !== 'edit' || (action === 'edit' && type === 'car')">
                    <input type="radio" name="options" id="option1" 
                        [attr.checked]="type === 'car' ? 'checked' : null"
                        (click)="selectType('car')">
                    <i class="das-icon icon-car"></i>
                    <span>{{'product.car' | translate}}</span>
                </label>
                <label class="btn btn-block" [class.active]="type === 'family'" *ngIf="action !== 'edit' || (action == 'edit' && type === 'family')">
                    <input type="radio" name="options" id="option2" 
                        [attr.checked]="type === 'family' ? 'checked' : null"
                        (click)="selectType('family')">
                    <i class="das-icon icon-family"></i>
                    <span>{{'product.family' | translate}}</span>
                </label>
                <label class="btn btn-block" [class.active]="type === 'building'" *ngIf="action !== 'edit' || (action == 'edit' && type === 'building')">
                    <input type="radio" name="options" id="option3" 
                        [attr.checked]="type === 'building' ? 'checked' : null"
                        (click)="selectType('building')">
                    <i class="das-icon icon-building"></i>
                    <span>{{'product.building' | translate}}</span>
                </label>
            </div>
        </div>

        <ng-container *ngIf="type === 'car' || type === 'building'">
            <div class="center separation">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-block" 
                        [class.active]="selectedRiskCategory?.id === type.id"
                        [class.invalid]="dropDownEmpty === true"
                        *ngFor="let type of riskTypes[type], index as i">
                        <input type="radio" name="types" id="type{{i}}"
                            [attr.checked]="selectedRiskCategory?.id === type.id ? 'checked' : null"
                            (click)="selectCategory(type)">
                        <i class="das-icon fas" 
                            *ngIf="!type.descriptionFr.includes('principale') && !type.descriptionFr.includes('Garages')"
                            [class.icon-private]="type.descriptionFr.includes('privé')"
                            [class.icon-professional]="type.descriptionFr.includes('professionnel') && !type.descriptionFr.includes('commercial')"
                            [class.icon-investBuilding]="type.descriptionFr.includes('rapport')"
                            [class.icon-coproperty]="type.descriptionFr.includes('Copropriété')"
                            [class.icon-industry]="type.descriptionFr.includes('commercial')">
                        </i>
                        <fa-icon 
                            *ngIf="type.descriptionFr.includes('principale')"
                            [icon]="faHouseUser">
                        </fa-icon>
                        <fa-icon 
                            *ngIf="type.descriptionFr.includes('Garages')"
                            [icon]="faWarehouse">
                        </fa-icon>
                        <span>{{type['description' + appService.getDescriptionLanguage()]}}</span>
                    </label>
                </div>
            </div>
    
            <div class="form-group">
                <label for="riskCode" *ngIf="type === 'car'">{{'product.vehicleType' | translate}}</label>
                <label for="riskCode" *ngIf="type === 'building'">{{'product.formula' | translate}}</label>
                <div id="riskCode" ngbDropdown class="d-inline-block">
                    <button type="button" (click)="ifEmpty()" class="btn btn-outline-primary" id="dropdownForm1" ngbDropdownToggle [ngClass]="{ 'is-invalid': submitted && f.riskCode.errors }">
                        <span *ngIf="loadDescription" class="spinner-border spinner-border-sm mr-1"></span>
                        <ng-container *ngIf="selectedRiskType != null">
                            {{selectedRiskType['description' + appService.getDescriptionLanguage()]}}
                        </ng-container>
                        <ng-container *ngIf="selectedRiskType == null">
                            {{'makeAchoice' | translate}}
                        </ng-container>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="menu">
                        <ul>
                            <ng-container *ngFor="let risk of selectedRiskCategory?.subItems">
                                <li>
                                    <button type="button" ngbDropdownItem (click)="chooseRiskType(risk, selectedRiskCategory)" title="{{risk['description' + appService.getDescriptionLanguage()]}}">
                                        <fa-icon class="caret" [icon]="faCaretRight" *ngIf="risk.subMenu === 1 && (risk.expanded === false || risk.expanded == null)"></fa-icon>
                                        <fa-icon class="caret" [icon]="faCaretDown" *ngIf="risk.subMenu === 1 && risk.expanded === true"></fa-icon>
                                        <span>{{risk['description' + appService.getDescriptionLanguage()]}}</span>
                                        <fa-icon class="info" [icon]="faInfoCircle" *ngIf="risk.errorMessage != null" [title]="risk.errorMessage['description' + appService.getDescriptionLanguage()]"></fa-icon>
                                    </button>
                                </li>
                                <ul *ngIf="risk.expanded === true">
                                    <ng-container *ngFor="let subRisk of risk?.subItems">
                                        <li>
                                            <button type="button" ngbDropdownItem (click)="chooseRiskType(subRisk, risk)" title="{{subRisk['description' + appService.getDescriptionLanguage()]}}">
                                                <fa-icon class="caret" [icon]="faCaretRight" *ngIf="subRisk.subMenu === 1 && (subRisk.expanded === false || subRisk.expanded == null)"></fa-icon>
                                                <fa-icon class="caret" [icon]="faCaretDown" *ngIf="subRisk.subMenu === 1 && subRisk.expanded === true"></fa-icon>
                                                <span>{{subRisk['description' + appService.getDescriptionLanguage()]}}</span>
                                                <fa-icon class="info" [icon]="faInfoCircle" *ngIf="subRisk.errorMessage != null" [title]="subRisk.errorMessage['description' + appService.getDescriptionLanguage()]"></fa-icon>
                                            </button>
                                        </li>
                                        <ul *ngIf="subRisk.expanded === true">
                                            <li *ngFor="let subSubRisk of subRisk?.subItems">
                                                <button type="button" ngbDropdownItem (click)="chooseRiskType(subSubRisk, subRisk)" title="{{subSubRisk['description' + appService.getDescriptionLanguage()]}}">
                                                    <span>{{subSubRisk['description' + appService.getDescriptionLanguage()]}}</span>
                                                    <fa-icon class="info" [icon]="faInfoCircle" *ngIf="subSubRisk.errorMessage != null" [title]="subSubRisk.errorMessage['description' + appService.getDescriptionLanguage()]"></fa-icon>
                                                </button>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div *ngIf="submitted && f.riskCode.errors" class="invalid-feedback forceDisplay">
                    <div *ngIf="f.riskCode.errors.required && type === 'car'">'{{'product.vehicleType' | translate }}' {{'product.fieldRequired' | translate}}</div>
                    <div *ngIf="f.riskCode.errors.required && type === 'building'">'{{'product.formula' | translate }}' {{'product.fieldRequired' | translate}}</div>
                </div>
            </div>
        </ng-container>
        
        <div class="form-group" *ngIf="type === 'family'">
            <label for="riskCode">{{'product.formula' | translate}}</label>
            <select id="riskCode" class="form-control" formControlName="riskCode" [ngClass]="{ 'is-invalid': submitted && f.riskCode.errors }">
                <option *ngIf="f.riskCode.value == null" value="null">{{'makeAchoice' | translate}}</option>
                <option *ngFor="let type of riskTypes[type]" value="{{type.code}}" (click)="chooseRiskType(type)">{{type['description' + appService.getDescriptionLanguage()]}}</option>
            </select>
            <div *ngIf="submitted && f.riskCode.errors" class="invalid-feedback">
                <div *ngIf="f.riskCode.errors.required">'{{'product.formula' | translate }}' {{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <!-- TO DO : add calendar widget and help tooltip -->
        <div class="form-group">
            <label for="startDate">{{'product.startDate' | translate}}</label>
            <div class="input-group">
                <input type="date" class="form-control" id="startDate" formControlName="startDate" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />
            </div>
            <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">'{{'product.startDate' | translate }}' {{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="type === 'family'">
            <label for="insuredPerson">{{'product.person' | translate}}</label>
            <input type="text" class="form-control" id="insuredPerson" formControlName="insuredPerson" />
        </div>
        <div class="form-group row">
            <div class="col custom-control custom-control-inline custom-checkbox" *ngIf="type !== 'car'">
                <input class="custom-control-input" type="checkbox" [(ngModel)]="address" [ngModelOptions]="{standalone: true}" id="address">
                <label class="custom-control-label" for="address">
                    {{'product.address' | translate}}
                </label>
            </div>
            <div class="col custom-control custom-control-inline custom-checkbox" *ngIf="type !== 'car'">
                <input class="custom-control-input" type="checkbox" [(ngModel)]="secondHome" [ngModelOptions]="{standalone: true}" id="secondHome">
                <label class="custom-control-label" for="secondHome">
                    {{'product.secondHome' | translate}}
                </label>
            </div>
        </div>
        <div class="form-group" *ngIf="type === 'car' && selectedRiskType?.layout !== 'MOBI_03'">
            <label for="plateNumber">{{'product.plateNumber' | translate}}</label>
            <input type="text" class="form-control" id="plateNumber" formControlName="plateNumber" [ngClass]="{ 'is-invalid': submitted && f.plateNumber.errors }" />
            <div *ngIf="submitted && f.plateNumber.errors" class="invalid-feedback">
                <div *ngIf="f.plateNumber.errors.required">'{{'product.plateNumber' | translate }}' {{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="type === 'car' && !['MOBI_02', 'MOBI_03', 'MOBI_06'].includes(selectedRiskType?.layout)">
            <label for="package">{{'product.familyPackage' | translate}}</label>
            <select id="package" class="form-control" formControlName="package" [ngClass]="{ 'is-invalid': submitted && f.package.errors }">
                <option value="1">{{'yes' | translate}}</option>
                <option value="2">{{'no' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.package.errors" class="invalid-feedback">
                <div *ngIf="f.package.errors.required">'{{'product.familyPackage' | translate }}' {{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-check" *ngIf="type === 'car' && selectedRiskType?.layout !== 'MOBI_03'">
            <input class="form-check-input" type="checkbox" formControlName="insuranceCompany" id="insuranceCompany">
            <label class="form-check-label" for="insuranceCompany">
                {{'product.insuranceContest' | translate}}
            </label>
        </div>
        <div class="row">
            <div class="col" *ngIf="(type === 'family' || type === 'building') && address">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="street">{{'product.street' | translate}}</label>
                            <input type="text" class="form-control" id="street" formControlName="addressStreet" [ngClass]="{ 'is-invalid': submitted && f.addressStreet.errors }" />
                            <div *ngIf="submitted && f.addressStreet.errors" class="invalid-feedback">
                                <div *ngIf="f.addressStreet.errors.required">'{{'product.street' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="number">{{'product.number' | translate}}</label>
                            <input type="text" class="form-control" id="number" formControlName="addressNumber" [ngClass]="{ 'is-invalid': submitted && f.addressNumber.errors }" />
                            <div *ngIf="submitted && f.addressNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.addressNumber.errors.required">'{{'product.number' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="box">{{'product.boxnumber' | translate}}</label>
                            <input type="text" class="form-control" id="box" formControlName="addressBox">
                        </div>
                        <div class="form-group">
                            <label for="city">{{'product.city' | translate}}</label>
                            <input type="text" class="form-control" id="city" formControlName="addressCity" [ngClass]="{ 'is-invalid': submitted && f.addressCity.errors }" />
                            <div *ngIf="submitted && f.addressCity.errors" class="invalid-feedback">
                                <div *ngIf="f.addressCity.errors.required">'{{'product.city' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="zip">{{'product.zip' | translate}}</label>
                            <input type="text" class="form-control" id="zip" formControlName="addressZip" [ngClass]="{ 'is-invalid': submitted && f.addressZip.errors }" />
                            <div *ngIf="submitted && f.addressZip.errors" class="invalid-feedback">
                                <div *ngIf="f.addressZip.errors.required">'{{'product.zip' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <!-- TODO: code pays + récupérer la liste via API-->
                        <!-- TODO: type de résidence-->
                    </div>
                </div>
            </div>
            <div class="col" *ngIf="(type === 'family' || type === 'building') && secondHome">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="street">{{'product.street' | translate}}</label>
                            <input type="text" class="form-control" id="street" formControlName="secondHomeStreet" [ngClass]="{ 'is-invalid': submitted && f.secondHomeStreet.errors }" />
                            <div *ngIf="submitted && f.secondHomeStreet.errors" class="invalid-feedback">
                                <div *ngIf="f.secondHomeStreet.errors.required">'{{'product.street' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="number">{{'product.number' | translate}}</label>
                            <input type="text" class="form-control" id="number" formControlName="secondHomeNumber" [ngClass]="{ 'is-invalid': submitted && f.secondHomeNumber.errors }" />
                            <div *ngIf="submitted && f.secondHomeNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.secondHomeNumber.errors.required">'{{'product.number' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="box">{{'product.boxnumber' | translate}}</label>
                            <input type="text" class="form-control" id="box" formControlName="secondHomeBox">
                        </div>
                        <div class="form-group">
                            <label for="city">{{'product.city' | translate}}</label>
                            <input type="text" class="form-control" id="city" formControlName="secondHomeCity" [ngClass]="{ 'is-invalid': submitted && f.secondHomeCity.errors }" />
                            <div *ngIf="submitted && f.secondHomeCity.errors" class="invalid-feedback">
                                <div *ngIf="f.secondHomeCity.errors.required">'{{'product.city' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="zip">{{'product.zip' | translate}}</label>
                            <input type="text" class="form-control" id="zip" formControlName="secondHomeZip" [ngClass]="{ 'is-invalid': submitted && f.secondHomeZip.errors }" />
                            <div *ngIf="submitted && f.secondHomeZip.errors" class="invalid-feedback">
                                <div *ngIf="f.secondHomeZip.errors.required">'{{'product.zip' | translate}}'{{'product.fieldRequired' | translate}}</div>
                            </div>
                        </div>
                        <!-- TODO: code pays + récupérer la liste via API-->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="center">
    <button type="submit" form="riskForm" class="btn btn-primary">{{'confirm' | translate}}</button>
</div>