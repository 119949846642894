import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SimulationService } from 'src/app/services/simulation.service';
import { AppService } from 'src/app/services/app.service';
import { LoginService } from 'src/app/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    providers: [
        LoginService,
        NgbProgressbarConfig
    ]
})
export class RedirectComponent implements OnInit {
    progressValue: number;
    progressText: string;
    type: string;
    constructor(
        private cookieService: CookieService,
        public appService: AppService,
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        config: NgbProgressbarConfig,
    ) {
        config.max = 1000;
        config.striped = true;
        config.animated = true;
        config.type = 'success';
        config.height = '20px';
        this.type = this.route.snapshot.paramMap.get('type');
    }

    ngOnInit(): void {
        this.progressValue = 333;
        this.progressText = 'Getting token';
        // get cookie puis call login puis redirect
        let tokenId;
        let redirectUrl;
        switch (this.type) {
            case 'newcontract':
                tokenId = this.cookieService.get('RiskCalculator');
                redirectUrl = '/production/offer/edit';
                break;
            case 'homepage':
                tokenId = this.cookieService.get('Extranet');
                redirectUrl = '/dashboard';
                break;
        }
        if (tokenId !== undefined && tokenId !== '') {
            this.loginService.loginFromToken(tokenId)
                .subscribe(resp => {
                    this.progressValue = 666;
                    this.progressText = 'Token retrieved, redirecting';
                    this.appService.setLogin(resp.login);
                    this.appService.setToken(resp.token);
                    if (resp.body['Language'] === 'F') {
                        this.appService.setLanguage('fr');
                    } else {
                        this.appService.setLanguage('nl');
                    }

                    if (redirectUrl === '/dashboard') {
                        this.router.navigate([redirectUrl]);
                    } else {
                        this.router.navigate([redirectUrl, resp.simulation.offerId, 0]);
                    }
                }, err => {
                    this.progressValue = 0;
                    this.progressText = 'Token expired';
                });
        } else {
            this.progressText = 'No cookie';
            this.progressValue = 0;
        }
    }

}
