<div class="dashboardTile">
    <div class="arrow">
        <img src="../../../assets/img/arrow-circle.svg" />
    </div>
    <div class="content">
        <h2>{{'production' | translate}}</h2>
        <p class="description">{{'create new offer' | translate}}</p>
        
        <p><a routerLink="/production/offer/create/0" class="btn btn-primary">{{'new offer' | translate}} / {{'offer' | translate}}</a></p>
        <p>
            <a routerLink="/production/offers">{{'offer management' | translate}}</a>
        </p>
        <p *ngIf="offersToDo > 0">{{'there are still' | translate}} <a routerLink="/production/offers" class="bubble">{{offersToDo}}</a> {{'offers to be made' | translate}}.</p>
    </div>
</div>