<div class="login-header">
    <div class="flex-item">
        <a routerLink="/login" routerLinkActive="active"
            title="Terug naar de homepage"
            rel="noreferrer">
            <img alt="DAS" class="logo" src="/assets/img/logo.png" *ngIf="appService.isDas === true"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_fr.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'fr'"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_nl.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'nl'"/>
            <img alt="AP" class="logo" src="/assets/img/dvv_fr.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'fr'"/>
            <img alt="DVV" class="logo" src="/assets/img/dvv_nl.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'nl'"/>
        </a>
        <h3>Extranet</h3>
        <p><ngb-progressbar [value]="progressValue"></ngb-progressbar></p>
        <p>{{progressText}}</p>
    </div>
</div>