import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Offer } from '../models/offer';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Login } from '../models/login';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private currentTokenSubject: BehaviorSubject<string>;
    private currentUserSubject: BehaviorSubject<Login>;
    public currentUser: Observable<Login>;
    public currentToken: Observable<string>;
    public isBelfius: boolean;
    public isDvv: boolean;
    public isDas: boolean;
    language: string;
    token: string;
    login: Login;
    natmask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/]; // 93.03.18-517.44
    bcemask = [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]; // 0401.620.778
    IBANmask = [/^[a-zA-Z]+$/, /^[a-zA-Z]+$/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // BExx-xxxx-xxxx-xxxx
    constructor(
        private translate: TranslateService,
        private toastr: ToastrService,
        private http?: HttpClient)
    {
        this.currentTokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));
        this.currentUserSubject = new BehaviorSubject<Login>(JSON.parse(localStorage.getItem('currentUser')));
        this.isBelfius = environment.isBelfius;
        this.isDvv = environment.isDvv;
        this.isDas = environment.isDas;
        this.token = localStorage.getItem('token');
        this.login = JSON.parse(localStorage.getItem('currentUser'));
    }

    public currentUserValue(): Login {
        return this.currentUserSubject.value;
    }

    public currentTokenValue(): string {
        return this.currentTokenSubject.value;
    }

    public setLogin(login) {
        this.login = login;
        localStorage.setItem('currentUser', JSON.stringify(login));
        this.currentUserSubject.next(login);
    }

    public setToken(token) {
        localStorage.setItem('token', token);
        this.token = token;
        this.currentTokenSubject.next(token);
    }

    public getOptions() {
        let options = {};
        options = {
            'X-language': (this.language != undefined) ? this.language : '',
            'X-auth': this.getToken()
        };

        return {headers: options};
    }

    public getToken() {
        // TODO : handle this correctly
        if (this.token == null || this.token === '') {
            // token for WTO {"brokerNumber": 909812, "Language": "fr"}
            this.token = 'zzzzzzzzzzzzzzzzzzzzzzzzzzzz';
        }
        return this.token;
    }

    public handleError(error) {
        this.toastr.error(error.message, 'Error');
    }

    public handleSuccess(success) {
        this.toastr.success(success, 'Success');
    }

    public getLanguage(): string {
        return this.language;
    }

    public setLanguage(language: string): void {
        if (language === undefined || language === null || language === '') {
            this.language = 'nl';
        } else {
            this.language = language;
        }

        this.translate.setDefaultLang(language);
        this.translate.use(language);
    }

    public getDescriptionLanguage(): string {
        const lang = this.getLanguage();
        return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
    }

    public checkIBAN(value): boolean {
        value = value.replace(/[-]/g, '');
        return this.isValidIBANNumber(value);
        // this.errorIBAN = !this.isValidIBANNumber(input);
        // if (!this.errorIBAN) {
        //    this.customerDataQuestion.iban = input;
        // }
    }

    public isValidIBANNumber(input): boolean {
        const CODE_LENGTHS = {
            // AE: 23, AZ: 28, BA: 20, BH: 22, BR: 29, DO: 28, FO: 18, GL: 18,
            // GT: 28, HR: 21, IL: 23, JO: 30, KW: 30, KZ: 20, LB: 28, MD: 24,
            // ME: 22, MK: 19, MR: 27, MU: 30, PK: 24, PS: 29, QA: 29, RS: 22,
            // SA: 24, TN: 24, TR: 26 -> NON SEPA
            AD: 24, AT: 20, BE: 16, BG: 22, CR: 21,
            CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, EE: 20,
            ES: 24, FI: 18, FR: 27, GB: 22, GI: 23,
            GR: 27, HU: 28, IE: 22, IS: 26, IT: 27,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27,
            MT: 31, NL: 18, NO: 15, PL: 28, PT: 25,
            RO: 24, SE: 24, SI: 19, SK: 24, SM: 27
        };

        const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits;

        if (code !== null && code !== undefined && code !== '') {
            if (CODE_LENGTHS[code[1]] !== undefined && CODE_LENGTHS[code[1]] !== null && CODE_LENGTHS[code[1]] !== '') {
                let checkMask = this.IBANmask;
                checkMask = checkMask.filter((d) => d !== '-');

                if (checkMask.length !== CODE_LENGTHS[code[1]]) {
                    for (let i = 1; i < CODE_LENGTHS[code[1]]; i++) {
                        if (i === 1 ) {
                            this.IBANmask = [/^[a-zA-Z]+$/, /^[a-zA-Z]+$/];
                        } else {
                            if ((i % 4) === 0) {
                                this.IBANmask.push('-', /\d/);
                            } else {
                                this.IBANmask.push(/\d/);
                            }
                        }
                    }
                }
            } else {
                this.IBANmask = [/^[a-zA-Z]+$/, /^[a-zA-Z]+$/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            }
        }

        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }

        // tslint:disable-next-line:only-arrow-functions
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
            return letter.charCodeAt(0) - 55;
        });

        return this.mod97(digits) === 1;
    }

    private mod97(digit): number {
        let checksum = digit.slice(0, 2);
        let fragment;

        for (let offset = 2; offset < digit.length; offset += 7) {
            fragment = checksum + digit.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }

        return checksum;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
