<div class="white-container with-progressbar">
    <form appFocusInvalidInput [formGroup]="form" (ngSubmit)="onSubmit()" id="dataClientForm">
        <div class="form-group">
            <label for="lastName">{{'product.lastname' | translate}}</label>
            <div class="help">
                <fa-icon [icon]="faQuestionCircle" placement="top" ngbTooltip="Tooltip on top" tooltipClass="das-tooltip"></fa-icon>
            </div>
            <input type="text" (blur)="f.lastName.setValue($event.target.value.toUpperCase())" class="form-control" id="lastName" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">'{{'product.lastname' | translate}}' {{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="firstName">{{'product.firstname' | translate}}</label>
            <input type="text" (blur)="f.firstName.setValue($event.target.value.toUpperCase())" class="form-control" id="firstName" formControlName="firstName">
        </div>
        <div class="form-group">
            <label for="co">{{'product.co' | translate}}</label>
            <input type="text" class="form-control" id="co" formControlName="co">
        </div>
        <div class="form-group">
            <label>{{'product.addressAutocomplete' | translate}}</label>
            <app-google-places (setAddress)="getAddress($event)" adressType="geocode"></app-google-places>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                    <label for="street">{{'product.street' | translate}}</label>
                    <input type="text" class="form-control" id="street" formControlName="street" [ngClass]="{ 'is-invalid': submitted && f.street.errors }" />
                    <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                        <div *ngIf="f.street.errors.required">'{{'product.street' | translate}}'{{'product.fieldRequired' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="number">{{'product.number' | translate}}</label>
                    <input type="text" class="form-control" id="number" formControlName="number" [ngClass]="{ 'is-invalid': submitted && f.number.errors }" />
                    <div *ngIf="submitted && f.number.errors" class="invalid-feedback">
                        <div *ngIf="f.number.errors.required">'{{'product.number' | translate}}'{{'product.fieldRequired' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="boxNumber">{{'product.boxnumber' | translate}}</label>
                    <input type="text" class="form-control" id="boxNumber" formControlName="boxNumber">
                </div>
                <div class="form-group">
                    <label for="city">{{'product.city' | translate}}</label>
                    <input type="text" class="form-control" id="city" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                        <div *ngIf="f.city.errors.required">'{{'product.city' | translate}}'{{'product.fieldRequired' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="zip">{{'product.zip' | translate}}</label>
                    <input type="text" class="form-control" id="zip" formControlName="zip" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" />
                    <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                        <div *ngIf="f.zip.errors.required">'{{'product.zip' | translate}}'{{'product.fieldRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="language">{{'product.language' | translate}}</label>
            <select id="language" class="form-control" formControlName="language" [ngClass]="{ 'is-invalid': submitted && f.language.errors }">
                <option value="1">{{'fr' | translate}}</option>
                <option value="2">{{'nl' | translate}}</option>
                <option value="3">{{'de' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.language.errors" class="invalid-feedback">
                <div *ngIf="f.language.errors.required">'{{'product.language' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="phone">{{'product.telephone' | translate}}</label>
            <input type="text" class="form-control" id="phone" formControlName="phone"/>
        </div>
        <div class="form-group">
            <label for="digitalConsent">{{'product.digitalconsent' | translate}}</label>
            <select id="digitalConsent" class="form-control" formControlName="digitalConsent">
                <option value="1">{{'yes' | translate}}</option>
                <option value="0">{{'no' | translate}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="email">{{'product.email' | translate}}</label>
            <input type="email" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.email">'{{'product.email' | translate}}'{{'product.fieldInvalid' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="nationalNumber">{{'product.nationalnumber' | translate}}</label>
            <input type="text" class="form-control" id="nationalNumber" formControlName="nationalNumber" 
                [ngClass]="{ 'is-invalid': submitted && f.nationalNumber.errors }"
                [textMask]="{mask: appService.natmask}"
                (change)="setBirthDate($event.target.value)" />
            <div *ngIf="submitted && f.nationalNumber.errors" class="invalid-feedback">
                <!-- TODO: required uniquement s'il y a un risque Benefoutre -->
                <div *ngIf="f.nationalNumber.errors.required">'{{'product.nationalnumber' | translate}}'{{'product.fieldRequired' | translate}}</div>
                <div *ngIf="f.nationalNumber.errors.invalid">'{{'product.nationalnumber' | translate}}'{{'product.fieldInvalid' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="iban">{{'product.iban' | translate}}</label>
            <input type="text" class="form-control" id="iban" formControlName="iban" 
                [ngClass]="{ 'is-invalid': submitted && f.iban.errors }" 
                [textMask]="{mask: appService.IBANmask}"    />
            <div *ngIf="submitted && f.iban.errors" class="invalid-feedback">
                <div *ngIf="f.iban.errors.invalid">'{{'product.iban' | translate}}'{{'product.fieldInvalid' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="bic">{{'product.bic' | translate}}</label>
            <input type="text" class="form-control" id="bic" formControlName="bic" [ngClass]="{ 'is-invalid': submitted && f.bic.errors }" />
            <div *ngIf="submitted && f.bic.errors" class="invalid-feedback">
                <div *ngIf="f.bic.errors.required">'{{'product.bic' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="bceNumber">{{'product.bcenumber' | translate}}</label>
            <input type="text" class="form-control" id="bceNumber" formControlName="bceNumber" [ngClass]="{ 'is-invalid': submitted && f.bceNumber.errors }" />
            <div *ngIf="submitted && f.bceNumber.errors" class="invalid-feedback">
                <div *ngIf="f.bceNumber.errors.required">'{{'product.bcenumber' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="status">{{'product.status' | translate}}</label>
            <select id="status" class="form-control" formControlName="status">
                <option value="1">{{'legal person' | translate}}</option>
                <option value="2">{{'natural person' | translate}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="birthDate">{{'product.birthdate' | translate}}</label>
            <input type="date" class="form-control" id="birthDate" formControlName="birthDate" [ngClass]="{ 'is-invalid': submitted && f.birthDate.errors }" />
            <div *ngIf="submitted && f.birthDate.errors" class="invalid-feedback">
                <div *ngIf="f.birthDate.errors.required">'{{'product.birthdate' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
    </form>
</div>
<div class="center">
    <button type="submit" form="dataClientForm" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'confirm' | translate}}
    </button>
</div>