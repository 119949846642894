import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OfferService } from 'src/app/services/offer.service';
import { AppService } from 'src/app/services/app.service';
import { formatDate } from '@angular/common';
import { Risk } from 'src/app/models/risk';
import { Vehicles } from 'src/app/enums/vehicles.enum';
import { TranslateService } from '@ngx-translate/core';
import { Offer } from 'src/app/models/offer';
import { RiskType } from 'src/app/models/risk-type';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { faHouseUser, faWarehouse, faCaretRight, faCaretDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-risk-edit',
    templateUrl: './risk-edit.component.html',
    styleUrls: ['./risk-edit.component.scss']
})
export class RiskEditComponent implements OnInit {
    typeId: number;
    type: string;
    offerId: number;
    form: FormGroup;
    risk: Risk;
    offer: Offer;
    submitted: boolean;
    address: boolean;
    secondHome: boolean;
    mode: string;
    action: string;
    riskTypes: {'car': Array<RiskType>, 'family': Array<RiskType>, 'building': Array<RiskType>};
    selectedRiskType: RiskType;
    selectedRiskCategory: RiskType;
    types: Array<string> = ['car', 'building'];
    @ViewChild(NgbDropdown)
    dropdown: NgbDropdown;
    faHouseUser = faHouseUser;
    faWarehouse = faWarehouse;
    faCaretRight = faCaretRight;
    faCaretDown = faCaretDown;
    faInfoCircle = faInfoCircle;
    dropDownEmpty: boolean;
    selectTree: Array<RiskType> = [];
    loadDescription = false;
    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private offerService: OfferService,
        public appService: AppService,
        config: NgbDropdownConfig,
    ) {
        config.autoClose = false;
        config.placement = 'bottom-left';
        this.type = 'car';
    }

    ngOnInit() {
        this.selectedRiskType = null;
        this.selectedRiskCategory = null;
        this.riskTypes = {car: [], family: [], building: []};
        this.mode = this.route.snapshot.paramMap.get('mode');
        this.action = this.route.snapshot.paramMap.get('action');
        this.offerId = Number(this.route.snapshot.paramMap.get('id'));
        this.submitted = false;

        this.getRiskTypes(0);
        if (this.action === 'edit') {
            this.typeId = Number(this.route.snapshot.paramMap.get('typeId'));
            this.loadDescription = true;
            this.getRisk();
        } else { // create
            this.risk = new Risk();
            this.risk.offerId = this.offerId;
            this.offerService.getOffer(this.risk.offerId).subscribe(offer => {
                this.offer = offer;
                this.risk.startDate = this.offer.startDate;
                this.setRiskCode();
                this.createForm();
            });
        }
    }

    get f() { return this.form.controls; }

    getRiskTypes(parentId: number) {
        this.offerService.getRiskTypes(parentId)
            .subscribe(riskTypes => {
                riskTypes.forEach(risk => {
                    switch (risk.category) {
                        case 'IMMO':
                            this.riskTypes.building.push(risk);
                            break;
                        case 'MOBI':
                            this.riskTypes.car.push(risk);
                            break;
                        case 'FAMILY':
                            this.riskTypes.family.push(risk);
                            break;
                    }
                });
            }, err => this.appService.handleError(err));
    }

    getRisk() {
        this.offerService.getRisk(this.typeId, this.offerId)
            .subscribe(risk => {
                switch (risk.type.toString().slice(0, 1)) {
                    case '2':
                        this.type = 'family';
                        break;
                    case '3':
                        this.type = 'building';
                        break;
                    case '4':
                        this.type = 'car';
                        break;
                }
                this.risk = risk;
                this.createForm();

                // on sélectionne la catégorie principale (this.getRiskTypes(0))
                this.selectedRiskCategory = this.riskTypes[this.type]
                    .filter(riskCat => riskCat.riskCategory === risk.riskType.riskCategory && riskCat.sequence === 0)[0];

                // on initialise l'arbre de sélection du risque
                this.initSelectType(risk.riskType);
            }, err => this.appService.handleError(err));
    }

    initSelectType(risk: RiskType) {
        this.selectTree.push(risk); // le premier = risque sélectionné, le dernier = catégorie mère

        if (risk.parentId !== 0) { // tant qu'il y a un parentId on récupère la catégorie correspondante
            this.offerService.getRiskType(risk.parentId)
            .subscribe(riskType => {
                this.initSelectType(riskType);
            }, err => this.appService.handleError(err));
        } else {
            // lorsqu'on a toute les catégories, on simule le clic sur chaque catégorie pour arriver au risque sélectionné
            let index = this.selectTree.length;
            while (index > 1) {
                index--;
                this.chooseRiskType(this.selectTree[index - 1], this.selectTree[index]);
            }
        }
    }

    setRiskCode() {
        if (this.offer.risks !== undefined) {
            let riskType;
            switch (this.type) {
                case 'car':
                    if (this.offer.risks.some(risk => risk.type.toString().slice(0, 1) === '4')) {
                        riskType = Math.max.apply(
                            Math,
                            this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '4')
                                .map((risk) => risk.type)
                        ) + 1;
                    } else {
                        riskType = 401;
                    }

                    this.typeId = riskType;
                    this.risk.type = riskType;
                    break;
                case 'family':
                    if (this.offer.risks.some(risk => risk.type.toString().slice(0, 1) === '3')) {
                        riskType = Math.max.apply(
                            Math,
                            this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '3')
                                .map((risk) => risk.type)
                        ) + 1;
                    } else {
                        riskType = 301;
                    }
                    this.typeId = riskType;
                    this.risk.type = riskType;
                    break;
                case 'building':
                    if (this.offer.risks.some(risk => risk.type.toString().slice(0, 1) === '2')) {
                        riskType = Math.max.apply(
                            Math,
                            this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '2')
                                .map((risk) => risk.type)
                        ) + 1;
                    } else {
                        riskType = 201;
                    }
                    this.typeId = riskType;
                    this.risk.type = riskType;
                    break;
            }
        }
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        if (this.mode === 'offer') {
            if (this.action === 'edit') {
                this.offerService.updateRisk(this.typeId, this.offerId, this.form.getRawValue())
                    .subscribe(risk => {
                        this.router.navigate(['/production/offer/edit', this.offerId, 2]);
                    }, err => this.appService.handleError(err));
            } else {
                this.offerService.createRisk(this.form.getRawValue())
                    .subscribe(risk => {
                        this.router.navigate(['/production/offer/edit', this.risk.offerId, 2]);
                    }, err => this.appService.handleError(err));
            }
        } else {
            if (this.mode === 'policy') {
                
            }
        }
    }

    createForm() {
        switch (this.type) {
            case 'car':
                this.createCarForm();
                if (this.risk.insuranceCompany === null || this.risk.insuranceCompany === undefined) {
                    this.f.insuranceCompany.setValue(0);
                }
                break;
            case 'family':
                this.createFamilyForm();
                break;
            case 'building':
                this.createBuildingForm();
                break;
            default :
                this.createCarForm();
                break;
        }

        if (this.risk.startDate === null || this.risk.startDate === undefined) {
            this.f.startDate.setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
        } else {
            this.f.startDate.setValue(formatDate(this.risk.startDate, 'yyyy-MM-dd', 'en'));
        }
    }

    createCarForm() {
        this.form = new FormGroup({
            offerId: new FormControl(this.risk.offerId),
            type: new FormControl(this.risk.type),
            riskCode: new FormControl(this.risk.riskCode, Validators.required),
            startDate: new FormControl(this.risk.startDate, Validators.required), // TO DO : date validator
            plateNumber: new FormControl(this.risk.plateNumber, Validators.required),
            package: new FormControl(this.risk.package == null ? '2' : this.risk.package, Validators.required),
            insuranceCompany: new FormControl(this.risk.insuranceCompany),
            description: new FormControl(this.risk.description),
            riskTypeId: new FormControl(this.risk.riskTypeId)
        });
    }

    createFamilyForm() {
        this.form = new FormGroup({
            offerId: new FormControl(this.risk.offerId),
            type: new FormControl(this.risk.type),
            riskCode: new FormControl(this.risk.riskCode, Validators.required),
            startDate: new FormControl(this.risk.startDate, Validators.required), // TO DO : date validator
            insuredPerson: new FormControl(this.risk.insuredPerson == null ? '' : this.risk.insuredPerson),
            addressStreet: new FormControl(this.risk.addressStreet == null ? '' : this.risk.addressStreet),
            addressNumber: new FormControl(this.risk.addressNumber == null ? '' : this.risk.addressNumber),
            addressBox: new FormControl(this.risk.addressBox == null ? '' : this.risk.addressBox),
            addressCity: new FormControl(this.risk.addressCity == null ? '' : this.risk.addressCity),
            addressZip: new FormControl(this.risk.addressZip == null ? '' : this.risk.addressZip),
            addressCountry: new FormControl(this.risk.addressCountry == null ? '' : this.risk.addressCountry),
            addressTypeOfHouse: new FormControl(this.risk.addressTypeOfHouse == null ? '' : this.risk.addressTypeOfHouse),
            secondHomeStreet: new FormControl(this.risk.secondHomeStreet == null ? '' : this.risk.secondHomeStreet),
            secondHomeNumber: new FormControl(this.risk.secondHomeNumber == null ? '' : this.risk.secondHomeNumber),
            secondHomeBox: new FormControl(this.risk.secondHomeBox == null ? '' : this.risk.secondHomeBox),
            secondHomeCity: new FormControl(this.risk.secondHomeCity == null ? '' : this.risk.secondHomeCity),
            secondHomeZip: new FormControl(this.risk.secondHomeZip == null ? '' : this.risk.secondHomeZip),
            secondHomeCountry: new FormControl(this.risk.secondHomeCountry == null ? '' : this.risk.secondHomeCountry),
            description: new FormControl(this.risk.description),
            riskTypeId: new FormControl(this.risk.riskTypeId)
        });

        if (this.f.addressStreet.value !== null && this.f.addressStreet.value.trim() !== '') {
            this.address = true;
        }
        if (this.f.secondHomeStreet.value !== null && this.f.secondHomeStreet.value.trim() !== '') {
            this.secondHome = true;
        }
    }

    createBuildingForm() {
        this.form = new FormGroup({
            offerId: new FormControl(this.risk.offerId.toString()),
            type: new FormControl(this.risk.type.toString()),
            riskCode: new FormControl(this.risk.riskCode, Validators.required),
            startDate: new FormControl(this.risk.startDate, Validators.required), // TO DO : date validator
            addressStreet: new FormControl(this.risk.addressStreet == null ? '' : this.risk.addressStreet),
            addressNumber: new FormControl(this.risk.addressNumber == null ? '' : this.risk.addressNumber),
            addressBox: new FormControl(this.risk.addressBox == null ? '' : this.risk.addressBox),
            addressCity: new FormControl(this.risk.addressCity == null ? '' : this.risk.addressCity),
            addressZip: new FormControl(this.risk.addressZip == null ? '' : this.risk.addressZip),
            addressCountry: new FormControl(this.risk.addressCountry == null ? '' : this.risk.addressCountry),
            addressTypeOfHouse: new FormControl(this.risk.addressTypeOfHouse == null ? '' : this.risk.addressTypeOfHouse),
            secondHomeStreet: new FormControl(this.risk.secondHomeStreet == null ? '' : this.risk.secondHomeStreet),
            secondHomeNumber: new FormControl(this.risk.secondHomeNumber == null ? '' : this.risk.secondHomeNumber),
            secondHomeBox: new FormControl(this.risk.secondHomeBox == null ? '' : this.risk.secondHomeBox),
            secondHomeCity: new FormControl(this.risk.secondHomeCity == null ? '' : this.risk.secondHomeCity),
            secondHomeZip: new FormControl(this.risk.secondHomeZip == null ? '' : this.risk.secondHomeZip),
            secondHomeCountry: new FormControl(this.risk.secondHomeCountry == null ? '' : this.risk.secondHomeCountry),
            description: new FormControl(this.risk.description),
            riskTypeId: new FormControl(this.risk.riskTypeId)
        });

        if (this.f.addressStreet.value !== null && this.f.addressStreet.value.trim() !== '') {
            this.address = true;
        }
        if (this.f.secondHomeStreet.value !== null && this.f.secondHomeStreet.value.trim() !== '') {
            this.secondHome = true;
        }
    }

    setDescriptionValue(value) {
        switch (this.type) {
            case 'car':
                this.translate.get(Vehicles['v' + value]).subscribe((description: string) => {
                    this.f.description.setValue(description);
                });
        }
    }

    collapseType(type: RiskType) {
        this.riskTypes.car.map(risk => {
            risk.expanded = false;
        });
        this.riskTypes.building.map(risk => {
            risk.expanded = false;
        });
        type.expanded = !type.expanded;
    }

    chooseRiskType(type: RiskType, parentType: RiskType = null) {
        if (type.subMenu === 1) { // si l'élement sur lequel on a cliqué est une catégorie
            if (type.subItems == null) { // on vérifie si les sous éléments sont chargés
                this.offerService.getRiskTypes(type.parentId).subscribe(subItems => {
                    if (type.subSequence === 0) {
                        this.selectedRiskCategory.subItems = subItems;
                        this.selectedRiskCategory.subItems.filter(item => item.id === type.id)[0].expanded = !type.expanded;
                    } else {
                        this.selectedRiskCategory.subItems
                            .filter(item => Number(item.id) === type.parentId)[0].subItems = subItems;
                        this.selectedRiskCategory.subItems
                            .filter(item => Number(item.id) === type.parentId)[0].subItems
                            .filter(subItem => subItem.id === type.id)[0].expanded = !type.expanded;
                    }
                });
            } else {
                if (type.subSequence === 0) {
                    this.selectedRiskCategory.subItems.filter(item => item.id === type.id)[0].expanded = !type.expanded;
                } else {
                    this.selectedRiskCategory.subItems
                        .filter(item => Number(item.id) === type.parentId)[0].subItems
                        .filter(subItem => subItem.id === type.id)[0].expanded = !type.expanded;
                }
            }
        } else { // l'élement sélectionné n'est pas un sous menu
            this.f.riskCode.setValue(type.code);
            this.f.description.setValue(type['description' + this.appService.getDescriptionLanguage()]);
            this.f.riskTypeId.setValue(type.id);
            this.selectedRiskType = type;
            // this.setDescriptionValue(type.code);
            let descriptionFr;
            let descriptionNl;

            if (type.subSubSequence !== 0) {
                const category1 = this.selectedRiskCategory.subItems.filter(item => Number(item.id) === parentType.parentId)[0];
                descriptionFr = category1.descriptionFr + ' > ' + parentType.descriptionFr + ' > ' + type.descriptionFr;
                descriptionNl = category1.descriptionNl + ' > ' + parentType.descriptionNl + ' > ' + type.descriptionNl;

                this.selectedRiskType.descriptionFr = descriptionFr;
                this.selectedRiskType.descriptionNl = descriptionNl;
            } else {
                if (type.subSequence !== 0) {
                    descriptionFr = parentType.descriptionFr + ' > ' + type.descriptionFr;
                    descriptionNl = parentType.descriptionNl + ' > ' + type.descriptionNl;

                    this.selectedRiskType.descriptionFr = descriptionFr;
                    this.selectedRiskType.descriptionNl = descriptionNl;
                }
            }

            this.loadDescription = false;

            if (this.type === 'car') {
                switch (type.layout) {
                    case 'MOBI_02':
                    case 'MOBI_06':
                        this.f.package.clearValidators();
                        this.f.package.setValue('2');
                        this.f.package.updateValueAndValidity();
                        break;
                    case 'MOBI_03':
                        this.f.package.clearValidators();
                        this.f.package.setValue('2');
                        this.f.plateNumber.clearValidators();
                        this.f.plateNumber.setValue('');
                        this.f.package.updateValueAndValidity();
                        break;
                    default:
                        this.f.package.setValidators([Validators.required]);
                        this.f.plateNumber.setValidators([Validators.required]);
                        this.f.package.updateValueAndValidity();
                }
            }

            if (this.dropdown != null) { // si family ce n'est pas un dropdown
                this.dropdown.close();
            }
        }
    }

    selectType(type) {
        this.type = type;
        this.setRiskCode();
        this.createForm();
    }

    selectCategory(category) {
        this.selectedRiskCategory = category;
        this.dropDownEmpty = false;
    }

    ifEmpty() {
        if (this.selectedRiskCategory == null) {
            this.dropDownEmpty = true;
            this.dropdown.close();
        }
    }
}
