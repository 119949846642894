<div class="dashboardTile">
    <div class="arrow">
        <img src="../../../assets/img/arrow-circle.svg" />
    </div>
    <div class="content">
        <h2>Legal Risk Calculator®</h2>
        <p class="description">{{'simulations.description' | translate}}</p>
        <p><app-new-simulation-button [title]="'simulations.newCalculation'"></app-new-simulation-button></p>
        <p>
            <a routerLink="/simulations/list">{{'simulations.management' | translate}}</a>
        </p>
        <p *ngIf="nbSimulations > 0">{{'simulations.thereAre' | translate}} <a routerLink="/simulations/list" class="bubble">{{nbSimulations}}</a> {{'simulations.simulationsExisting' | translate}}.</p>
    </div>
</div>