import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomiciliatedPolicy } from 'src/app/models/domiciliated-policy';
import { Office } from 'src/app/models/office';
import { Policy } from 'src/app/models/policy';
import { AppService } from 'src/app/services/app.service';
import { DomiciliatedPolicyService } from 'src/app/services/domiciliated-policy.service';
import { OfficeService } from 'src/app/services/office.service';
import { PolicyService } from 'src/app/services/policy.service';
import { faFileSignature, faBook, faHandHoldingUsd, faAt, faWallet, faAsterisk, faExchangeAlt, faBan, faSlash, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { AmendmentService } from 'src/app/services/amendment.service';

@Component({
    selector: 'app-policies-view-contract',
    templateUrl: './policies-view-contract.component.html',
    styleUrls: ['./policies-view-contract.component.scss'],
    providers: [PolicyService]
})
export class PoliciesViewContractComponent {
    id: number;
    amendmentId: number;
    policy: Policy;
    office: Office;
    domPolicy: DomiciliatedPolicy;
    domStatus: string;
    historyList: Array<object>;
    currentAmendment: string;
    currentAmendmentId: number;
    loading: boolean;
    loadAction: boolean;
    faFileSignature = faFileSignature;
    faBook = faBook;
    faHandHoldingUsd = faHandHoldingUsd;
    faAt = faAt;
    faWallet = faWallet;
    faAsterisk = faAsterisk;
    faExchangeAlt = faExchangeAlt;
    faBan = faBan;
    faSlash = faSlash;
    faFileContract = faFileContract;
    receiptDueDate: Date;
    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        public appService: AppService,
        private policyService: PolicyService,
        private officeService: OfficeService,
        private domiciliatedPolicyService: DomiciliatedPolicyService,
        private amendmentService: AmendmentService
    ) {
        this.id = 0;
        this.amendmentId = 0;
        this.policy = null;
        this.office = null;
        this.domPolicy = null;
        this.domStatus = null;
        this.historyList = [];
        this.currentAmendment = '';

        route.params.subscribe(params => {
            this.loading = true;
            this.receiptDueDate = null;
            this.init(params);
        });
    }

    init(params): void {
        if (Number(params.id) > 0) {
            this.id = Number(params.id);
            this.amendmentId = Number(params.amendmentId);
            this.getPolicy();
            this.getOfficeInformations();
            if (this.historyList.length === 0) {
                this.getAllAmendments();
            }
        }
    }

    amendmentInit() {
        this.loadAction = true;
        this.amendmentService.amendmentInit(this.id.toString()).subscribe(res => {
            this.loadAction = false;
            this.router.navigate([`/production/policy/edit/${this.policy.id}/${this.currentAmendmentId}/2`]);
        });
    }

    getPolicy() {
        this.policyService.getPolicy(this.id.toString(), this.amendmentId.toString())
            .subscribe(policy => {
                this.policy = policy;
                this.currentAmendmentId = policy.amendmentId;
                this.currentAmendment = this.formatPolicyAmendmentId(
                    policy.policyType,
                    policy.id.toString(),
                    policy.amendmentId.toString());

                this.getDomiciliatedPolicy(this.policy.domiciliation.toString());
                this.checkReceipt(this.policy.cancellationCodeSignification,
                    this.policy.id.toString(),
                    this.policy.amendmentId.toString(),
                    this.policy.clientId.toString());
                this.loading = false;
            }, err => this.appService.handleError(err));
    }

    getOfficeInformations() {
        this.officeService.getOffice('0') // 0 = siège principal (bx)
            .subscribe(office => {
                this.office = office;
            });
    }

    getDomiciliatedPolicy(domiciliation: string) {
        if (domiciliation !== '0' && domiciliation !== '') {
            this.domiciliatedPolicyService.getDomiciliatedPolicy(this.id.toString())
            .subscribe(dom => {
                this.domPolicy = dom;
                if (dom == null) {
                    this.domStatus = dom.status;
                } else {
                    this.domStatus = 'NOT YET ACTIVE';
                }
            });
        } else {
            this.domStatus = 'NO';
        }
    }

    getAllAmendments() {
        this.policyService.getAllAmendments(this.id.toString())
            .subscribe(amendments => {
                amendments.forEach(amendment => {
                    this.historyList.push({
                        display: this.formatPolicyAmendmentId(
                            amendment.policyType,
                            amendment.id.toString(),
                            amendment.amendmentId.toString()),
                        routerLink: `/production/policy/view/${amendment.id}/${amendment.amendmentId}`
                    });
                });
            }, err => this.appService.handleError(err));
    }

    formatPolicyAmendmentId(type: string, id: string, amendmentId: string) {
        return type + '/' + ('0000000' + id.toString()).slice(-7) + '/' + ('00' + amendmentId.toString()).slice(-2);
    }

    checkReceipt(cancellationCode: string, id: string, amendmentId: string, clientId: string) {
        if (cancellationCode === 'checkReceipt') {
            this.policyService.getReceipt(id, amendmentId, clientId).subscribe(receipt => {
                if (receipt.disputeCode <= 3) {
                    this.policy.cancellationCodeSignification = 'UNPAID PRIME FOR DUE DATE';
                    this.receiptDueDate = receipt.startDate;
                } else {
                    this.policy.cancellationCodeSignification = '';
                }
            });
        }
    }
}
