import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
    offersToDo: number;
    constructor(
        private appService: AppService,
        private offerService: OfferService) {
        this.offersToDo = 0;
    }

    ngOnInit() {
        this.offerService.getCurrentOffers()
            .subscribe(res => {
                this.offersToDo = res.filter(el => el.username === this.appService.login.username && el.policyNumber === '').length;
            }, error => this.appService.handleError(error));
    }

}
