import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Offer } from '../models/offer';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { RateCode } from '../models/rate-code';
import { Risk } from '../models/risk';
import { RiskType } from '../models/risk-type';

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    constructor(
        private appService: AppService,
        private http?: HttpClient
    ) {
    }

    public getOffer(id: number): Observable<Offer> {
        return this.http.get<Offer>(environment.api + `offers/${id}`, this.appService.getOptions());
    }

    public getOffers(): Observable<Array<Offer>> {
        return this.http.get<Array<Offer>>(environment.api + `offers`, this.appService.getOptions());
    }

    public getCurrentOffers(): Observable<Array<Offer>> {
        return this.http.get<Array<Offer>>(environment.api + `offers`,
            this.appService.getOptions());
    }

    public getCurrentRateCode(): Observable<RateCode> {
        return this.http.get<RateCode>(environment.api + `ratecodes`, this.appService.getOptions());
    }

    public updateOffer(offerId: number, offer: Offer): Observable<Offer> {
        return this.http.put<Offer>(environment.api + `offers/${offerId}`, offer, this.appService.getOptions());
    }

    public getRisk(type: number, offerId: number): Observable<Risk> {
        return this.http.get<Risk>(environment.api + `risks/${offerId}/${type}`, this.appService.getOptions());
    }

    public updateRisk(type: number, offerId: number, risk: Risk): Observable<Risk> {
        return this.http.put<Risk>(environment.api + `risks/${offerId}/${type}`, risk, this.appService.getOptions());
    }

    public createOffer(offer: Offer): Observable<Offer> {
        return this.http.post<Offer>(environment.api + `offers`, offer, this.appService.getOptions());
    }

    public deleteOffer(id: number): Observable<any> {
        return this.http.delete<any>(environment.api + `offers/${id}`, this.appService.getOptions());
    }

    public deleteRisk(offerId: number, type: number): Observable<any> {
        return this.http.delete<any>(environment.api + `risks/${offerId}/${type}`, this.appService.getOptions());
    }

    public createRisk(risk: Risk): Observable<Risk> {
        return this.http.post<Risk>(environment.api + `risks`, risk, this.appService.getOptions());
    }

    public priceCalculation(offer: Offer): Observable<any> {
        return this.http.post<any>(environment.api + `calculs`, offer, this.appService.getOptions());
    }

    public createContract(offer: Offer): Observable<any> {
        return this.http.post<any>(environment.api + `contracts`, offer, this.appService.getOptions());
    }

    public getDocumentsForPolicy(policyId: string): Observable<any> {
        return this.http.get<any>(`${environment.api}documents/policy/${policyId}`, this.appService.getOptions());
    }

    public getDocument(document: string): Observable<Blob> {
        return this.http.get(`${environment.api}documents/get/${document}`, { responseType: 'blob' });
    }

    public getDocuments(document: object): Observable<Blob> {
        return this.http.post(`${environment.api}documents/all`, document, { responseType: 'blob' });
    }

    public createDocument(document: object): Observable<Blob> {
        return this.http.post(`${environment.api}documents/`, document, { responseType: 'blob' });
    }

    public getRiskTypes(parentId: number): Observable<Array<RiskType>> {
        return this.http.get<Array<RiskType>>(`${environment.api}risks/types/${parentId.toString()}`, this.appService.getOptions());
    }

    public getRiskType(id: number): Observable<RiskType> {
        return this.http.get<RiskType>(`${environment.api}risks/${id.toString()}`, this.appService.getOptions());
    }
}
