import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Office } from '../models/office';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  constructor(
      private appService: AppService,
      private http?: HttpClient
    ) {
  }

  public getOffice(id: string): Observable<Office> {
      return this.http.get<Office>(environment.api + `offices/${id}`, this.appService.getOptions());
  }
}
