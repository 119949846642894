<div class="white-container">
    <h2 class="na">Revue des offres et nouvelles affaires établies</h2>
    <a routerLink="/production/offer/create/0" class="btn btn-primary">Nouvelle affaire / offre</a>
    <div class="center margin15">
        <div class="custom-control custom-control-inline custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="cb1" [(ngModel)]="onlyOffers" (click)="checkOffersToDisplay($event.target.checked, colleaguesOffers)">
            <label class="custom-control-label" for="cb1">{{ 'Montrer uniquement les offres' | translate }}</label>
        </div>
        <div class="custom-control custom-control-inline custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="cb2" [(ngModel)]="colleaguesOffers" (click)="checkOffersToDisplay(onlyOffers, $event.target.checked)">
            <label class="custom-control-label" for="cb2">{{ 'Montrer également les offres de mes collègues' | translate }}</label>
        </div>
    </div>
    <table class="table">
        <thead>
            <th>{{'Date' | translate}}</th>
            <th>{{'Name' | translate}}</th>
            <th *ngIf="colleaguesOffers">{{'Username' | translate}}</th>
            <th>{{'Contract' | translate}}</th>
            <th></th>
            <th></th>
        </thead>
        <tbody>
            <tr *ngFor="let offer of offersToDisplay">
                <td>{{offer.date | date:'dd/MM/yyyy'}}</td>
                <td>{{offer.lastName}} {{offer.firstName}}</td>
                <td *ngIf="colleaguesOffers">{{offer.username}}</td>
                <td *ngIf="offer.policyNumber === ''">{{'Offer' | translate}}</td>
                <td *ngIf="offer.policyNumber !== ''">{{offer.policyNumber}}</td>
                <td>
                    <a (click)="deleteOffer(offer.id)" *ngIf="offer.username === appService.login.username && offer.policyNumber === ''">
                        <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </a>
                </td>
                <td>
                    <a [routerLink]="['/production/offer/edit', offer.id, 0]">
                        <fa-icon [icon]="faPencilAlt"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #confirm let-c="close" let-d="dismiss">
    <app-confirm [message]="'message.confirmDeleteOffer'" [c]="c" [d]="d"></app-confirm>
</ng-template>