<div class="white-container with-progressbar">
    <form appFocusInvalidInput [formGroup]="form" (ngSubmit)="onSubmit()" id="dataContractForm">
        <div class="form-group">
            <label for="startDate">{{'product.startDate' | translate}}</label>
            <input type="date" class="form-control" id="startDate" 
                formControlName="startDate" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }"
                (focusout)="setDueDate($event.target.value)" />
            <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">'{{'product.startDate' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="dueDate">{{'product.dayMonth' | translate}}</label>
            <input type="text" class="form-control" id="dueDate" formControlName="dueDate" [ngClass]="{ 'is-invalid': submitted && f.dueDate.errors }" />
            <div *ngIf="submitted && f.dueDate.errors" class="invalid-feedback">
                <div *ngIf="f.dueDate.errors.required">'{{'product.dayMonth' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="cashingMode">{{'product.cashingMode' | translate}}</label>
            <select id="cashingMode" class="form-control" formControlName="cashingMode" [ngClass]="{ 'is-invalid': submitted && f.cashingMode.errors }">
                <option value="1">{{'product.cashingModeProducer' | translate}}</option>
                <option value="2">{{'product.cashingModeProducerCompany' | translate}}</option>
                <option value="3">{{'product.cashingModeCompany' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.cashingMode.errors" class="invalid-feedback">
                <div *ngIf="f.cashingMode.errors.required">'{{'product.cashingMode' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="domiciliation">{{'product.domiciliation' | translate}}</label>
            <select id="domiciliation" class="form-control" formControlName="domiciliation"
                [ngClass]="{ 'is-invalid': submitted && f.domiciliation.errors }" (change)="setIbanFieldRequired(f.domiciliation.value)">
                <option [ngValue]="false">{{'no' | translate}}</option>
                <option [ngValue]="true">{{'yes' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.domiciliation.errors" class="invalid-feedback">
                <div *ngIf="f.domiciliation.errors.required">'{{'product.domiciliation' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="paymentFrequency">{{'product.paymentFrequency' | translate}}</label>
            <select *ngIf="!newRateCode && f.domiciliation.value === false" id="paymentFrequency" class="form-control" formControlName="paymentFrequency" [ngClass]="{ 'is-invalid': submitted && f.paymentFrequency.errors }">
                <option value="1">{{'product.yearlyPayment' | translate}}</option>
                <option value="2">{{'product.semesterPayment3%' | translate}}</option>
                <option value="3">{{'product.quarterPayment5%' | translate}}</option>
            </select>

            <select *ngIf="newRateCode && f.domiciliation.value === false" id="paymentFrequency" class="form-control" formControlName="paymentFrequency" [ngClass]="{ 'is-invalid': submitted && f.paymentFrequency.errors }">
                <option value="1">{{'product.yearlyPayment' | translate}}</option>
                <option value="2">{{'product.semesterPayment4%' | translate}}</option>
                <option value="3">{{'product.quarterPayment6%' | translate}}</option>
            </select>

            <select *ngIf="f.domiciliation.value === true" id="paymentFrequency" class="form-control" formControlName="paymentFrequency" [ngClass]="{ 'is-invalid': submitted && f.paymentFrequency.errors }">
                <option value="1">{{'product.yearlyPayment' | translate}}</option>
                <option value="2">{{'product.semesterPayment' | translate}}</option>
                <option value="3">{{'product.quarterPayment' | translate}}</option>
                <option value="4">{{'product.monthlyPayment' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.paymentFrequency.errors" class="invalid-feedback">
                <div *ngIf="f.paymentFrequency.errors.required">'{{'product.paymentFrequency' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="f.domiciliation.value === true">
            <label for="iban">{{'product.iban' | translate}}</label>
            <input type="text" id="iban" class="form-control" formControlName="iban" [ngClass]="{ 'is-invalid': submitted && f.iban.errors }" 
            [textMask]="{mask: appService.IBANmask}"/>
            <div *ngIf="submitted && f.iban.errors" class="invalid-feedback">
                <div *ngIf="f.iban.errors.invalid">'{{'product.iban' | translate}}'{{'product.fieldInvalid' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="commissionRate">{{'product.commission' | translate}}</label>
            <select id="commissionRate" class="form-control" formControlName="commissionRate" [ngClass]="{ 'is-invalid': submitted && f.commissionRate.errors }">
                <option value="0">{{'product.commissionTotal' | translate}}</option>
                <option value="1">{{'product.commissionNormal' | translate}}</option>
                <option value="2">{{'product.commission5%' | translate}}</option>
                <option value="3">{{'product.commission7%' | translate}}</option>
                <option value="4">{{'product.commission10%' | translate}}</option>
                <option value="5">{{'product.commission12%' | translate}}</option>
                <option value="6">{{'product.commission15%' | translate}}</option>
                <option value="7">{{'product.commission17%' | translate}}</option>
                <option value="8">{{'product.commission20%' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.commissionRate.errors" class="invalid-feedback">
                <div *ngIf="f.commissionRate.errors.required">'{{'product.commission' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="action">{{'product.transfer' | translate}}</label>
            <select id="action" class="form-control" formControlName="action" [ngClass]="{ 'is-invalid': submitted && f.action.errors }">
                <option value="1">{{'yes' | translate}}</option>
                <option value="2">{{'no' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.action.errors" class="invalid-feedback">
                <div *ngIf="f.action.errors.required">'{{'product.transfer' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas">
            <label for="brokerReference">{{'product.brokerReference' | translate}}</label>
            <input type="text" class="form-control" id="brokerReference" formControlName="brokerReference" />
        </div>
        <div class="form-group" *ngIf="environment.isDas && mode !== 'policy'">
            <label for="returnBlock">{{'product.returnBlock' | translate}}</label>
            <select id="returnBlock" class="form-control" formControlName="returnBlock" [ngClass]="{ 'is-invalid': submitted && f.returnBlock.errors }">
                <option value="0">{{'product.insuweb' | translate}}</option>
                <option value="1">{{'product.asweb' | translate}}</option>
                <option value="2">{{'no' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.returnBlock.errors" class="invalid-feedback">
                <div *ngIf="f.returnBlock.errors.required">'{{'product.returnBlock' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="environment.isDas && mode !== 'policy'">
            <label for="documentsPrinting">{{'product.documentsPrinting' | translate}}</label>
            <select id="documentsPrinting" class="form-control" formControlName="documentsPrinting" [ngClass]="{ 'is-invalid': submitted && f.documentsPrinting.errors }">
                <option value="1">{{'yes' | translate}}</option>
                <option value="2">{{'no' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.documentsPrinting.errors" class="invalid-feedback">
                <div *ngIf="f.documentsPrinting.errors.required">'{{'product.documentsPrinting' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="f.documentsPrinting.value === '1' && environment.isDas && mode !== 'policy'">
            <label for="documentsSending">{{'product.documentsSending' | translate}}</label>
            <select id="documentsSending" class="form-control" formControlName="documentsSending" [ngClass]="{ 'is-invalid': submitted && f.documentsSending.errors }">
                <option value="1">{{'product.atBroker' | translate}}</option>
                <option value="2">{{'product.atCustomer' | translate}}</option>
            </select>
            <div *ngIf="submitted && f.documentsSending.errors" class="invalid-feedback">
                <div *ngIf="f.documentsSending.errors.required">'{{'product.documentsSending' | translate}}'{{'product.fieldRequired' | translate}}</div>
            </div>
        </div>
    </form>
</div>
<div class="center">
    <button type="submit" form="dataContractForm" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'confirm' | translate}}
    </button>
</div>