import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-claim',
  templateUrl: './new-claim.component.html',
  styleUrls: ['./new-claim.component.scss']
})
export class NewClaimComponent implements OnInit {

  questionsList = [ /* TODO: create model -> à voir si les questions se retrouvent en db */
    {
      id: 1,
      question: 'Quel est le nom du preneur d\'assurance ou son numéro de police?',
      type: 'text',
      possibleAnswers: [],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 2,
      answer: []
    },
    {
      id: 2,
      question: 'Le preneur d\'assurance est-il/elle la personne impliqué(e)?',
      type: 'radio',
      possibleAnswers: ['oui', 'non'],
      conditionalQuestion: 3,
      conditionalAnswer: 'oui',
      nextQuestion: 4,
      answer: []
    },
    {
      id: 3,
      question: 'Y-a-t-il une personne supplémentaire d\'impliquée?',
      type: 'radio',
      possibleAnswers: ['oui', 'non'],
      conditionalQuestion: 4,
      conditionalAnswer: 'oui',
      nextQuestion: 5,
      answer: []
    },
    {
      id: 4,
      question: 'Quelles sont les données de contact de la personne impliquée?',
      type: 'text',
      possibleAnswers: [],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 5,
      answer: []
    },
    {
      id: 5,
      question: 'Qui est la partie adverse ?',
      type: 'radio',
      possibleAnswers: ['personne', 'société'],
      conditionalQuestion: 6,
      conditionalAnswer: 'personne',
      nextQuestion: 7,
      answer: []
    },
    {
      id: 6,
      question: 'Remplissez les coordonnées de la personne',
      type: 'text',
      possibleAnswers: ['nom', 'prénom', 'adresse', 'email', 'téléphone'],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 8,
      answer: []
    },
    {
      id: 7,
      question: 'Quelle est la société adverse?',
      type: 'text',
      possibleAnswers: [],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 8,
      answer: []
    },
    {
      id: 8,
      question: 'A quelle date s\'est déroulé le sinistre ?',
      type: 'date',
      possibleAnswers: [],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 9,
      answer: []
    },
    {
      id: 9,
      question: 'Où s\'est déroulé le sinistre?',
      type: 'radio',
      possibleAnswers: ['belgique', 'europe', 'le reste du monde'],
      conditionalQuestion: 10,
      conditionalAnswer: 'le reste du monde',
      nextQuestion: 11,
      answer: []
    },
    {
      id: 10,
      question: 'Dans quel pays?',
      type: 'radio',
      possibleAnswers: [],
      conditionalQuestion: null,
      conditionalAnswer: null,
      nextQuestion: 11,
      answer: []
    }
  ];

  questionToDisplay = [];
  step: number;
  answerToSave: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.step = 0;
  }

  ngOnInit(): void {

    this.step = Number(this.route.snapshot.paramMap.get('step'));

    if (this.step < 1) {
      this.step = 1;
      this.router.navigate(['/claims/create', this.step]);
    }

    this.questionToDisplay.push(this.questionsList.filter(question => question.id === this.step)[0]);
  }

  save(): void {
    const currentQuestion = this.questionsList.filter(question => question.id === this.step);

    if (currentQuestion[0].conditionalAnswer !== null) {
      if (this.answerToSave === currentQuestion[0].conditionalAnswer) {
        this.step = currentQuestion[0].conditionalQuestion;
      } else {
        this.step = currentQuestion[0].nextQuestion;
      }
    } else {
      this.step = currentQuestion[0].nextQuestion;
    }

    /* TODO: sauver la réponse */
    this.answerToSave = '';
    this.questionToDisplay.push(this.questionsList.filter(question => question.id === this.step)[0]);
    this.router.navigate(['/claims/create', this.step]);
  }

  setAnswer(answer) {
    this.answerToSave = answer;
  }
}
