import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Offer } from 'src/app/models/offer';
import { AppService } from 'src/app/services/app.service';
import { ibanValidator, natNumberValidator, emailValidator } from '../../validators/custom.validators';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferService } from 'src/app/services/offer.service';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Policy } from 'src/app/models/policy';

@Component({
    selector: 'app-edit-client-data',
    templateUrl: './edit-client-data.component.html',
    styleUrls: ['./edit-client-data.component.scss']
})
export class EditClientDataComponent implements OnInit, OnChanges {
    @Input() offer: Offer;
    @Input() mode: string;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    form: FormGroup;
    submitted: boolean;
    faQuestionCircle = faQuestionCircle;
    environment = environment;
    loading = false;
    clientOperation = 'E';
    constructor(
        public appService: AppService,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private offerService: OfferService) {}

    ngOnInit() {
        if (Number(this.route.snapshot.paramMap.get('id')) === 0) {
            this.form.patchValue({ // pour éviter d'avoir des valeurs null lors de l'insert
                lastName: '',
                firstName: '',
                co: '',
                street: '',
                number: '',
                boxNumber: '',
                city: '',
                zip: '',
                language: '',
                phone: '',
                digitalConsent: '',
                email: '',
                nationalNumber: '',
                iban: '',
                bic: '',
                bceNumber: '',
                status: '',
                birthDate: '',
                brokerId: this.appService.login.brokerId,
                username: this.appService.login.username,
                date: new Date(),
                startDate: new Date()
            });
        }
    }

    ngOnChanges() {
        this.submitted = false;
        let birthDate = null;
        if (this.offer.birthDate != undefined) {
            birthDate = formatDate(this.offer.birthDate, 'yyyy-MM-dd', 'en');
        }
        this.form = new FormGroup({
            lastName: new FormControl(this.offer.lastName, Validators.required),
            firstName: new FormControl(this.offer.firstName),
            co: new FormControl(this.offer.co),
            street: new FormControl(this.offer.street, Validators.required),
            number: new FormControl(this.offer.number, Validators.required),
            boxNumber: new FormControl(this.offer.boxNumber),
            city: new FormControl(this.offer.city, Validators.required),
            zip: new FormControl(this.offer.zip, Validators.required),
            language: new FormControl(this.offer.language, Validators.required),
            phone: new FormControl(this.offer.phone),
            digitalConsent: new FormControl(this.offer.digitalConsent),
            email: new FormControl(this.offer.email, emailValidator.bind(this)), // surcharge de Validators.email pour faire un trim
            nationalNumber: new FormControl(this.offer.nationalNumber, natNumberValidator.bind(this)),
            iban: new FormControl(this.offer.iban, ibanValidator.bind(this)),
            bic: new FormControl(this.offer.bic), // TODO : validator custom pour n° BIC
            bceNumber: new FormControl(this.offer.bceNumber), // TODO : validator custom pour n° BCE
            status: new FormControl(this.offer.status),
            birthDate: new FormControl(birthDate),
            brokerId: new FormControl(this.appService.login.brokerId),
            username: new FormControl(this.appService.login.username),
            date: new FormControl(new Date()),
            startDate: new FormControl(this.offer.startDate)
        });
    }

    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;

        if (this.form.invalid) {
            this.loading = false;
            return;
        }

        const formToJson = this.form.getRawValue();
        if (formToJson['iban'] != null) {
            formToJson['iban'] = formToJson['iban'].split('-').join('').toUpperCase();
        }

        if (this.mode === 'offer') {
            if (Number(this.route.snapshot.paramMap.get('id')) > 0) {
                // update wnp
                this.offerService.updateOffer(Number(this.route.snapshot.paramMap.get('id')), formToJson).subscribe(updatedOffer => {
                    this.appService.handleSuccess(updatedOffer.id);
                    this.loading = false;
                    this.update.emit(updatedOffer);
                    // this.router.navigate(['/production/offer/edit', updatedOffer.id, 1]);
                }, error => {
                    this.loading = false;
                    this.appService.handleError(error);
                });
            } else {
                // insert wnp
                this.offerService.createOffer(formToJson).subscribe(createdOffer => {
                    this.offerService.getOffer(createdOffer.id).subscribe((offer) => {
                        this.loading = false;
                        this.update.emit(offer);
                        // this.router.navigate(['/production/offer/edit', offer.id, 1]);
                    }, error => {
                        this.loading = false;
                        this.appService.handleError(error);
                    });
                }, error => {
                    this.loading = false;
                    this.appService.handleError(error);
                });
            }
        } else {
            if (this.mode === 'policy') {
                delete formToJson.date; // n'existe pas dans wnc
                this.detectChanges(formToJson, this.offer);
            }
        }
    }

    setBirthDate(bday) {

        let birthDateYYYY;
        let birthDateMM;
        let birthDateDD;
        let birthDate;

        bday = bday.replace(/[.]/g, '').replace(/[-]/g, '').replace(/[_]/g, '');

        if (bday.length === 11 && this.f.nationalNumber.errors === null) {
            if (Number(bday.substr(0, 2)) > 20 && Number(bday.substr(0, 2)) <= 99) {
                birthDateYYYY = '19' + bday.substr(0, 2);
            } else {
                birthDateYYYY = '20' + bday.substr(0, 2);
            }

            birthDateMM = bday.substr(2, 2);
            birthDateDD = bday.substr(4, 2);

            birthDate = birthDateYYYY + '-' + birthDateMM + '-' + birthDateDD;

            this.f.birthDate.setValue(formatDate(new Date(birthDate), 'yyyy-MM-dd', 'en'));
        }
    }

    getAddress(place: object) {
        const address = {street_number: '', route: '', locality: '', country: '', postal_code: ''};
        place['address_components'].forEach(comp => {
            address[comp.types[0]] = comp['long_name'];
        });
        this.form.controls.street.setValue(address.route);
        this.form.controls.city.setValue(address.locality);
        this.form.controls.zip.setValue(address.postal_code);
        this.form.controls.number.setValue(address.street_number);
    }

    detectChanges(objectToUpdate, currentObject) {
        if (this.mode === 'policy') {
            for (const key in objectToUpdate) {
                if (objectToUpdate[key] !== currentObject[key]) {
                    this.clientOperation = 'M';
                    return;
                }
            }
        }
    }
}
