import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFocusInvalidInput]'
})
export class FocusInvalidInputDirective {

  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit() {
    const invalidControl = this.el.nativeElement.querySelector('input.ng-invalid');
    const invalidControlSelect = this.el.nativeElement.querySelector('select.ng-invalid');

    if (invalidControl) {
      invalidControl.focus();
    } else {
      if (invalidControlSelect) {
        invalidControlSelect.focus();
      }
    }
  }

}
