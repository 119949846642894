<div class="white-container with-progressbar">
    <h2>{{title}}</h2>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{'document type' | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let document of documentsList">
                <td>{{ document['docName' + appService.getDescriptionLanguage()] | translate }}</td>
                <td>
                    <a (click)="downloadDocument(document)"><i class="das-icon icon-download"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="center">
        <button type="button" class="btn btn-primary" (click)="downloadAll(documentsList)">{{'Download all'}}</button>
    </div>
</div>