import { AbstractControl, Validators, FormControl } from '@angular/forms';

export function ibanValidator(control: AbstractControl) {
    if (control.value !== null && control.value !== '') {
        const input = control.value.replace(/[-]/g, '');
        if (this.appService.isValidIBANNumber(input)) {
            return null;
        } else {
            return { invalid: true };
        }
    }
    return null;
}

export function natNumberValidator(control: AbstractControl) {
    if (control.value !== null && control.value !== '' && control.value !== 0) {

        let birthDateYYYY;
        let birthDateMM;
        let birthDateDD;
        let birthDate;
        let digit;
        let natID;
        natID = control.value.toString().replace(/[.]/g, '').replace(/[-]/g, '').replace(/[_]/g, '');

        if (natID.length === 11) {
            if (Number(natID.substr(0, 2)) > 20 && Number(natID.substr(0, 2)) <= 99) {
                birthDateYYYY = '19' + natID.substr(0, 2);
            } else {
                birthDateYYYY = '20' + natID.substr(0, 2);
            }

            birthDateMM = natID.substr(2, 2);
            birthDateDD = natID.substr(4, 2);

            birthDate = birthDateYYYY + birthDateMM + birthDateDD;

            if (Number(birthDate) <= 19991231) {
                digit = 97 - (Number(natID.substr(0, 9)) % 97);
            } else {
                digit = 97 - (Number('2' + natID.substr(0, 9)) % 97);
            }

            if (digit === Number(natID.substr(9, 2))) {
                return null;
            } else {
                return { invalid: true };
            }
        } else {
            return { invalid: true };
        }
    }
    return null;
}

export function emailValidator(control: AbstractControl) {
    if (control.value !== null && control.value !== '') {
        const emailControl = new FormControl(control.value, Validators.email);
        return emailControl.errors;
    }
    return null;
}
