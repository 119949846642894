import { RiskType } from './risk-type';

export class Risk {
    offerId: number;
    type: number;
    riskCode: number;
    description: string;
    startDate: Date;
    plateNumber: string;
    package: number;
    insuranceCompany: number;
    contractNumber: string;
    contractEndDate: Date;
    insuredPerson: string;
    addressStreet: string;
    addressNumber: string;
    addressBox: string;
    addressCity: string;
    addressZip: string;
    addressCountry: string;
    addressTypeOfHouse: string;
    secondHomeStreet: string;
    secondHomeNumber: string;
    secondHomeBox: string;
    secondHomeCity: string;
    secondHomeZip: string;
    secondHomeCountry: string;
    netBonus: number;
    grossBonus: number;
    grossBonusMailing: number;
    rateBonus: number;
    chargeAccount1: number;
    chargeAccount2: number;
    chargeAccount3: number;
    chargeAccount4: number;
    chargeAccount5: number;
    riskTypeId: number;
    riskType: RiskType;
}
