import { Component, OnInit, Input } from '@angular/core';
import { Token } from 'src/app/models/token';
import { AppService } from 'src/app/services/app.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-new-simulation-button',
  templateUrl: './new-simulation-button.component.html',
  styleUrls: ['./new-simulation-button.component.scss']
})
export class NewSimulationButtonComponent implements OnInit {

  @Input() title: string;

  constructor(
    private appService: AppService,
    private simulationService: SimulationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
  }

  createSimulation() {
    const token: Token = {
        id: '',
        type: 'NEWPMECALC',
        body: `{"BrokerNumber":${this.appService.login.brokerId}, "Username":"${this.appService.login.username}", "Language":"${this.appService.getLanguage()}", "Value":""}`,
        date: null,
        time: null
    };
    this.simulationService.createToken(token)
        .subscribe(resp => {
            if (environment.envName === 'dev') {
                this.cookieService.set('RiskCalculator', resp.id, 0, '/');
                window.location.href = environment.lrcURL;
            } else {
                this.cookieService.set('RiskCalculator', resp.id, 0, '/', 'das.be');
                window.location.href = environment.lrcURL;
            }
        }, err => this.appService.handleError(err));
  }
}
