<div class="white-container">
    <h2 class="na">{{'simulations.management' | translate}}</h2>
    <app-new-simulation-button [title]="'simulations.start'"></app-new-simulation-button>
    <div class="center margin15">
        <div class="custom-control custom-control-inline custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="cb2" [(ngModel)]="colleaguesSimulations" (click)="checkSimulationsToDisplay($event.target.checked)">
            <label class="custom-control-label" for="cb2">{{ 'simulations.colleaguesSimulations' | translate }}</label>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>{{'simulations.id' | translate}}</th>
                <th>{{'simulations.companyName' | translate}}</th>
                <th>{{'simulations.bceNumber' | translate}}</th>
                <th>{{'simulations.date' | translate}}</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let simulation of simulationsToDisplay">
                <td>
                    {{simulation.id}}
                </td>
                <td>
                    {{simulation.companyName}}
                </td>
                <td>
                    {{simulation.bceNumber}}
                </td>
                <td>{{simulation.date | date:'dd/MM/yyyy'}}</td>
                <td>
                    <a (click)="resumeSimulation(simulation)"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                </td>
                <td>
                    <a (click)="deleteSimulation(simulation)"><fa-icon [icon]="faTrashAlt"></fa-icon></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #confirm let-c="close" let-d="dismiss">
    <app-confirm [message]="'message.confirmSimulationDeletion'" [c]="c" [d]="d"></app-confirm>
</ng-template>