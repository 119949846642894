<div class="white-container with-progressbar">
    <h2>{{'offerBonus.Offre pour' | translate}} {{offer?.lastName}} {{offer?.firstName}} {{'offerBonus.avec effet au' | translate}} {{offer?.startDate | date:'dd/MM/yyyy'}}</h2>
    <div class="center">
        <table class="table">
            <thead>
                <tr><th>{{'offerBonus.Prime annuelle (frais inclus)' | translate}}</th></tr>
            </thead>
            <tbody>
                <tr><td><h3>€ {{total | number:'1.2-2'}}</h3></td></tr>
            </tbody>
        </table>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{'product.startDate' | translate}}</th>
                <th scope="col">{{'product.risk' | translate}}</th>
                <th scope="col">{{'product.rateBonus' | translate}}</th>
                <th scope="col">{{'product.netBonus' | translate}}</th>
                <th scope="col">{{'product.bonus' | translate}}</th>
                <th scope="col">{{'product.grossBonus' | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let risk of offer?.risks; index as i">
                <td>
                    {{ risk.startDate | date:'dd/MM/yyyy' }}
                </td>
                <td>{{ risk.description }}</td>
                <td>{{ risk.rateBonus }}</td>
                <td>{{ risk.netBonus }}</td>
                <td>{{ risk.chargeAccount1 + risk.chargeAccount2 + risk.chargeAccount3 + risk.chargeAccount4 + risk.chargeAccount5 | number:'1.2-2' }}</td>
                <td>{{ risk.grossBonus }}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
    <div class="center">
        <table class="table">
            <thead>
                <tr>
                    <th>{{'offerBonus.Montant de la quittance (frais inclus)' | translate}}</th>
                    <th>{{'offerBonus.Période' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><h3>€ {{total2 | number:'1.2-2'}}</h3></td>
                    <td><h3>{{offer?.DEBQ | date:'dd/MM/yyyy'}} - {{offer?.FINQ | date:'dd/MM/yyyy'}}</h3></td>
                </tr>
            </tbody>
        </table>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{'product.startDate' | translate}}</th>
                <th scope="col">{{'product.risk' | translate}}</th>
                <th scope="col">{{'product.rateBonus' | translate}}</th>
                <th scope="col">{{'product.netBonus' | translate}}</th>
                <th scope="col">{{'product.bonus' | translate}}</th>
                <th scope="col">{{'product.grossBonus' | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let risk of offer?.risks; index as i">
                <td>
                    {{ risk.startDate | date:'dd/MM/yyyy' }}
                </td>
                <td>{{ risk.description }}</td>
                <td>{{ risk.rateBonus }}</td>
                <td>{{ risk.netBonusReceipt }}</td>
                <td>{{ risk.chargeAccount1Receipt + risk.chargeAccount2Receipt + risk.chargeAccount3Receipt + risk.chargeAccount4Receipt + risk.chargeAccount5Receipt | number:'1.2-2' }}</td>
                <td>{{ risk.grossBonusReceipt }}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<div class="center">
    <button type="button" (click)="createContract()" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'new offer' | translate}}
    </button>
</div>

<ng-template #confirm let-c="close" let-d="dismiss">
    <app-confirm [message]="'message.confirmCreateContract'" [c]="c" [d]="d"></app-confirm>
</ng-template>