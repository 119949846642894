import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Offer } from 'src/app/models/offer';
import { AppService } from 'src/app/services/app.service';
import { OfferService } from 'src/app/services/offer.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { ibanValidator } from '../../validators/custom.validators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Policy } from 'src/app/models/policy';

@Component({
    selector: 'app-edit-contract-data',
    templateUrl: './edit-contract-data.component.html',
    styleUrls: ['./edit-contract-data.component.scss']
})
export class EditContractDataComponent implements OnInit, OnChanges {

    @Input() offer: Offer;
    @Input() mode: string;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    form: FormGroup;
    submitted: boolean;
    newRateCode = false;
    environment = environment;
    loading = false;
    constructor(
        public translate: TranslateService,
        public appService: AppService,
        private offerService: OfferService) {}

    ngOnInit() {

    }

    ngOnChanges() {
        this.submitted = false;

        this.offerService.getCurrentRateCode().subscribe(res => {
            if (res.id > 18) {
                this.newRateCode = true;
            } else {
                this.newRateCode = false;
            }
        }, error => {
            this.appService.handleError(error);
        });

        this.form = new FormGroup({
            startDate: new FormControl(formatDate(this.offer.startDate, 'yyyy-MM-dd', 'en'), Validators.required), // TODO : validator date
            dueDate: new FormControl(this.offer.dueDate, Validators.required), // TODO : validator date
            cashingMode: new FormControl(this.offer.cashingModeAS400, Validators.required),
            domiciliation: new FormControl(this.offer.domiciliation, Validators.required),
            paymentFrequency: new FormControl(this.offer.paymentFrequency, Validators.required),
            iban: new FormControl(this.offer.iban),
            commissionRate: new FormControl(this.offer.commissionRateAS400, Validators.required),
            action: new FormControl(this.offer.action, Validators.required),
            brokerReference: new FormControl(this.offer.brokerReference),
            returnBlock: new FormControl(this.offer.returnBlockSending, Validators.required),
            documentsPrinting: new FormControl(this.offer.documentsPrinting, Validators.required),
            documentsSending: new FormControl(this.offer.documentsSending)
        });

        // fill default values
        if (this.offer.startDate === null) {
            this.f.startDate.setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
        }
        if (Number(this.offer.dueDate) === 0) {
            this.f.dueDate.setValue(moment(new Date()).format('DD/MM'));
        }
        if (this.offer.cashingMode === '') {
            this.f.cashingMode.setValue(this.appService.login.broker.cashing);
        }
        if (this.offer.domiciliation === true) {
            this.setIbanFieldRequired(true);
        }
        if (this.offer.commissionRate === '') {
            this.f.commissionRate.setValue('2');
        }
        if (this.offer.action === '') {
            this.f.action.setValue(this.appService.login.commercialAction); // TODO: manip à faire sur ce champ selon conditions (voir WD)
        } else {
            if (this.offer.action !== '1') { // cf db + WB -> en db action peut être vide ou = 1, 2, 3 mais en WD on gère que 1 ou 2
                this.f.action.setValue('2');
            }
        }
        if (this.offer.returnBlockSending === '') {
            this.f.returnBlock.setValue(this.appService.login.blocRetour);
        }
        if (this.offer.documentsPrinting === '') {
            this.f.documentsPrinting.setValue(this.appService.login.printDocByDAS);
        }
        if (this.offer.documentsSending === '') {
            this.f.documentsSending.setValue(this.appService.login.docSending);
        }
    }

    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;

        if (this.form.invalid) {
            this.loading = false;
            return;
        }

        const formToJson = this.form.getRawValue();
        if (formToJson['iban'] != null) {
            formToJson['iban'] = formToJson['iban'].split('-').join('').toUpperCase();
        }
        this.offerService.updateOffer(this.offer.id, formToJson).subscribe(updatedOffer => {
            this.loading = false;
            this.appService.handleSuccess(updatedOffer.id);
            this.update.emit(updatedOffer);
        }, error => {
            this.loading = false;
            this.appService.handleError(error);
        });
    }

    setIbanFieldRequired(value) {
        if (value === true) { // domiciliation = oui
            this.f.iban.setValidators([Validators.required, ibanValidator.bind(this)]);
        } else {
            if (this.f.paymentFrequency.value === '4') {
                this.f.paymentFrequency.setValue('1');
            }
            this.f.iban.clearValidators();
            this.f.iban.setValue('');
        }

        this.f.iban.updateValueAndValidity();
    }

    setDueDate(value) {
        const period = moment(value);
        this.f.dueDate.setValue(`${this.addZero(period.date())}/${this.addZero(period.month() + 1)}`);
    }

    addZero(nbr) {
        return nbr < 10 ? '0' + nbr.toString() : '' + nbr.toString();
    }
}
