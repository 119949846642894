import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { Offer } from 'src/app/models/offer';
import { AppService } from 'src/app/services/app.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferService } from 'src/app/services/offer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PolicyService } from 'src/app/services/policy.service';
import { Policy } from 'src/app/models/policy';

@Component({
    selector: 'app-edit-bonus-calculation',
    templateUrl: './edit-bonus-calculation.component.html',
    styleUrls: ['./edit-bonus-calculation.component.scss']
})
export class EditBonusCalculationComponent implements OnInit {
    // offer: Offer;
    total: number;
    total2: number;
    @Input() offer: Offer;
    @Input() mode: string;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('confirm', {static: true}) confirmModal: TemplateRef<any>;
    policyAlreadyExists: Policy;
    loading = false;
    constructor(
        public appService: AppService,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private offerService: OfferService,
        private policyService: PolicyService,
        private modalService: NgbModal) {}

    ngOnInit() {
        this.total = 0;
        this.total2 = Number(this.offer.PRBQ.replace(',', '.'));
        this.offer.risks.forEach(risk => {
            if (this.offer.actionMailing === true || risk.insuranceCompany !== 0) {
                this.total += risk.grossBonusMailing;
            } else {
                this.total += risk.grossBonus;
            }
        });
        if (this.total > 0) {
            this.total += 3;
        }

        const obj = {
            name: this.offer.lastName,
            firstname: this.offer.firstName,
            brokerId: this.appService.login.brokerId,
            zip: this.offer.zip
        };

        this.policyService.policyAlreadyExists(obj).subscribe(pol => {
            this.policyAlreadyExists = pol;
            if (pol != null) {
                this.translate.get('errors.CautionContractAlreadyExists').subscribe((errorMsg: string) => {
                    alert(errorMsg);
                });
            }
        });
    }

    checkContractDate(): boolean {
        if (this.policyAlreadyExists != null) {
            const today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
            const contractDate = formatDate(this.policyAlreadyExists.creationDate, 'yyyy-MM-dd', 'en');
            if (contractDate === today) {
                this.translate.get('errors.contractAlreadyExists').subscribe((errorMsg: string) => {
                    alert(errorMsg);
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    createContract() {
        if (this.checkContractDate()) {
            if (this.offer.policyNumber === '') {
                this.modalService.open(this.confirmModal).result.then((result) => {
                    if (result === 'confirm') {
                        this.loading = true;
                        this.offerService.createContract(this.offer).subscribe(res => {
                            this.loading = false;
                            this.offer = res;
                            this.update.emit(this.offer);
                        }, error => {
                            this.appService.handleError(error);
                        });
                    }
                }, (reason) => {
                });
            } else {
                this.update.emit(this.offer);
            }
        }
    }
}
