<div class="row">
    <div class="col-3 white-block" *ngIf="environment.isDas">
        <app-offers></app-offers>
    </div>
    <div class="col-3 white-block" *ngIf="environment.isDas">
        <app-policies></app-policies>
    </div>
    <div class="col-3 white-block" *ngIf="environment.isDas">
        <app-simulations></app-simulations>
    </div>
    <div class="col-3 white-block" *ngIf="environment.isDas">
        <app-claims></app-claims>
    </div>
</div>