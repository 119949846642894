import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { faHome, faCog, faAddressBook, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    faHome = faHome;
    faCog = faCog;
    faAddressBook = faAddressBook;
    faPowerOff = faPowerOff;
    environment = environment;
    @ViewChild('admin', {static: true}) adminModal: TemplateRef<any>;

    constructor(
        public appService: AppService,
        public router: Router,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        if (this.appService.login === undefined || this.appService.login === null) {
            this.router.navigate(['/login']);
        }
    }

    openAdminPanel(): void {
        this.modalService.open(this.adminModal);
    }

    reloadDashboardAs(event: any) {
        console.log(event);
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }

}
