<div class="dashboardTile">
    <div class="arrow">
        <img src="../../../assets/img/arrow-circle.svg" />
    </div>
    <div class="content">
        <h2>{{'amendments.title' | translate}}</h2>
        <p class="description">{{'amendments.subtitle' | translate}}</p>
        
        <p><a routerLink="/production/policies" class="btn btn-primary">{{'amendments.list' | translate}}</a></p>
    </div>
</div>