<div class="white-container">
    <h2>{{'amendments.management' | translate}}</h2>
    <form [formGroup]="searchForm" class="form-inline searchBar">
        <fa-icon *ngIf="!loading" [icon]="faSearch"></fa-icon>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <input class="form-control form-control-sm ml-3 w-75" formControlName="searchField" type="text" placeholder="{{'amendments.search' | translate}}" aria-label="Search">
    </form>

    <table class="table">
        <thead>
            <tr>
                <th>{{'amendments.effect' | translate}}</th>
                <th>{{'amendments.client' | translate}}</th>
                <th>{{'amendments.state' | translate}}</th>
                <th>{{'amendments.contract' | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let policy of policies">
                <td>
                    {{policy.startDate | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    {{policy.client.completeName}}
                </td>
                <td>
                    {{'amendments.code.' + policy.amendmentCodeSignification | translate}}
                </td>
                <td>
                    {{policy.id}}
                </td>
                <td>
                    <a routerLink="/production/policy/view/{{policy.id}}/{{policy.amendmentId}}"><fa-icon [icon]="faFileContract"></fa-icon></a>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="center"><span *ngIf="loadingTableBody" class="spinner-border spinner-border-sm mr-1"></span></div>
</div>