import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './services/app.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService, private appService: AppService, public router: Router) {
        translate.setDefaultLang('en');
        translate.use('nl');
    }
    title = 'extranet';

    public ngOnInit() {
        /* TODO : get token */
        this.appService.language = navigator.language.substr(0, 2);
        this.appService.setLanguage(this.appService.language);
    }
}
