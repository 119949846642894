<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'confirmation' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('cross')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{message | translate}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="c('cancel')">{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="c('confirm')">{{'confirm' | translate}}</button>
</div>