import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faFileContract, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Policy } from 'src/app/models/policy';
import { AppService } from 'src/app/services/app.service';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'app-policies-list',
  templateUrl: './policies-list.component.html',
  styleUrls: ['./policies-list.component.scss'],
  providers: [PolicyService]
})
export class PoliciesListComponent implements OnInit {

  faFileContract = faFileContract;
  faSearch = faSearch;
  policies: Array<Policy>;
  loading = false;
  loadingTableBody = false;
  error: string | null;

  public searchForm = new FormGroup({
    searchField: new FormControl('')
  });
  private term$: Observable<string>;

  constructor(
    public appService: AppService,
    private policyService: PolicyService) {
      this.term$ = this.searchForm.controls.searchField.valueChanges;
    }

  ngOnInit(): void {
    this.searchAllPolicies();

    this.term$.pipe(
      filter((term) => {
        term = term.trim();
        if (!term.length) { // empty input field
          this.searchAllPolicies();
        }
        return term && term.length > 2;
      }),
      debounceTime(300),
      // distinctUntilChanged(),
      tap((term) => {
        this.error = null;
        this.loading = true;
      }),
      switchMap((term) =>
        this.policyService.searchPolicies(term).pipe(
            catchError((err) => {
              this.loading = false;
              this.error = err;
              this.appService.handleError(err);
              return of([]);
          })
        )
      )
    )
    .subscribe((policiesList: Array<Policy>) => {
      this.loading = false;
      this.policies = policiesList;
    });
  }

  private searchAllPolicies() { // limit = 500
    this.loadingTableBody = true;
    this.policyService.getPolicies()
        .subscribe(policiesList => {
          this.loadingTableBody = false;
          this.policies = policiesList;
      }, err => this.appService.handleError(err));
  }
}
