<a class="header-button" (click)="open(content)" title="Admin mode">
    <fa-icon [icon]="faUserSecret"></fa-icon> 
</a>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Admin panel</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="searchForm" class="form-inline searchBar">
            <fa-icon *ngIf="!loading" [icon]="faSearch"></fa-icon>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <input class="form-control form-control-sm ml-3 w-75" formControlName="searchField" type="text" placeholder="{{'amendments.search' | translate}}" aria-label="Search">
        </form>
        <table class="table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Login</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let broker of brokers" (click)="chooseBroker(broker)">
                    <td>
                        {{broker.id}}
                    </td>
                    <td>
                        {{broker.name}}
                    </td>
                    <td>
                        {{broker.logins[0].username}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close()">{{'cancel' | translate}}</button>
    </div>
</ng-template>