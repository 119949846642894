<div class="dashboardTile">
    <div class="arrow">
        <img src="../../../assets/img/arrow-circle.svg" />
    </div>
    <div class="content">
        <h2>{{'claims.title' | translate}}</h2>
        <p class="description">{{'claims.subtitle' | translate}}</p>
        
        <p><a routerLink="/claims/create/0" class="btn btn-primary">{{'claims.new' | translate}}</a></p>
        <p>
            <a routerLink="/claims/list">{{'claims.management' | translate}}</a>
        </p>
    </div>
</div>