import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Offer } from 'src/app/models/offer';
import { PolicyService } from 'src/app/services/policy.service';
import { AppService } from 'src/app/services/app.service';
import { Policy } from 'src/app/models/policy';
import { OfferService } from 'src/app/services/offer.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-documents-download',
  templateUrl: './documents-download.component.html',
  styleUrls: ['./documents-download.component.scss']
})
export class DocumentsDownloadComponent implements OnInit {

    @Input() offer: Offer;
    @Input() type: string; // type = "NA" (newPol) / "OF" (offer) / "AV" (avenant)
    domiciliation: boolean;
    delayedDate: Date = new Date();
    today: Date = new Date();
    delayedEffect: boolean;
    title: string;
    documentsList: Array<{
        docNameFr: string,
        docNameNl: string,
        type: string,
        docCode: string,
        policyId: string,
        amendmentId: string,
        user: string,
        lang: string,
        clientId: string
    }> = [];
    policy: any; // Policy; TODO: ajouter un modèle de risque à policy
    lang: string;
    translationObjectFr: [];
    translationObjectNl: [];

    constructor(
        public translate: TranslateService,
        public appService: AppService,
        private offerService: OfferService
    ) {
        this.delayedDate.setMonth(this.delayedDate.getMonth() + 2);
        this.delayedDate.setDate(1);

        // pour n'avoir qu'un object pour les documents et conditions
        // obligé de faire comme ça car les documents créé par AS400 ont leur traduction dans le json
        // mais les traductions pour les conditions viennent de la db
        this.translate.getTranslation('fr').subscribe(translationObject => {
            this.translationObjectFr = translationObject.documents;
        });
        this.translate.getTranslation('nl').subscribe(translationObject => {
            this.translationObjectNl = translationObject.documents;
        });
    }

    ngOnInit(): void {
        this.offerService.getDocumentsForPolicy(this.offer.policyNumber)
            .subscribe(policy => {
                this.policy = policy;
                if (policy.domiciliation === '1') {
                    this.domiciliation = true;
                } else {
                    this.domiciliation = false;
                }

                if (policy.startDate <= this.delayedDate) {
                    this.delayedEffect = true;
                } else {
                    this.delayedEffect = false;
                }

                if (policy.client.lang === 4) {
                    this.lang = this.appService.login.lang;
                } else {
                    this.lang = policy.client.lang;
                }

                this.fillDocumentsTable(this.type);
            }, err => this.appService.handleError(err));
    }

    fillDocumentsTable(type) {
        switch (type) {
            case 'NA': // new contract
                // attestation
                this.documentsList.push({
                    docNameFr: this.translationObjectFr['certificate'],
                    docNameNl: this.translationObjectNl['certificate'],
                    type: 'attestation',
                    docCode: '',
                    policyId: this.policy.id,
                    amendmentId: this.policy.amendmentId,
                    user: this.appService.login.username,
                    lang: this.lang,
                    clientId: this.policy.clientId
                });

                if (this.delayedEffect === false) {
                    // quittance
                    this.documentsList.push({
                        docNameFr: this.translationObjectFr['payment'],
                        docNameNl: this.translationObjectNl['payment'],
                        type: 'quittance',
                        docCode: '',
                        policyId: this.policy.id,
                        amendmentId: this.policy.amendmentId,
                        user: this.appService.login.username,
                        lang: this.lang,
                        clientId: this.policy.clientId
                    });

                    if ((this.appService.login.brokerId >= 60100 && this.appService.login.brokerId < 61000) ||
                    this.appService.login.brokerId === 62715) {

                    // bordereau comptant
                    this.documentsList.push({
                        docNameFr: this.translationObjectFr['slip'],
                        docNameNl: this.translationObjectNl['slip'],
                        type: 'bordereau',
                        docCode: '',
                        policyId: this.policy.id,
                        amendmentId: this.policy.amendmentId,
                        user: this.appService.login.username,
                        lang: this.lang,
                        clientId: this.policy.clientId
                    });
                    }
                }

                // devoir d'information
                this.documentsList.push({
                    docNameFr: this.translationObjectFr['information'],
                    docNameNl: this.translationObjectNl['information'],
                    type: 'information',
                    docCode: '',
                    policyId: this.policy.id,
                    amendmentId: this.policy.amendmentId,
                    user: this.appService.login.username,
                    lang: this.lang,
                    clientId: this.policy.clientId
                });

                // domiciliation
                if (this.domiciliation) {
                    this.documentsList.push({
                        docNameFr: this.translationObjectFr['domiciliation'],
                        docNameNl: this.translationObjectNl['domiciliation'],
                        type: 'domiciliation',
                        docCode: '',
                        policyId: this.policy.id,
                        amendmentId: this.policy.amendmentId,
                        user: this.appService.login.username,
                        lang: this.lang,
                        clientId: this.policy.clientId
                    });
                    this.documentsList.push({
                        docNameFr: this.translationObjectFr['domiciliationInformation'],
                        docNameNl: this.translationObjectNl['domiciliationInformation'],
                        type: '',
                        docCode: 'SDD',
                        policyId: this.policy.id,
                        amendmentId: this.policy.amendmentId,
                        user: this.appService.login.username,
                        lang: this.lang,
                        clientId: this.policy.clientId
                    });
                }

                // conditions générales
                this.documentsList.push({
                    docNameFr: this.translationObjectFr['generalTermsAndConditions'],
                    docNameNl: this.translationObjectNl['generalTermsAndConditions'],
                    type: '',
                    docCode: '5010',
                    policyId: this.policy.id,
                    amendmentId: this.policy.amendmentId,
                    user: this.appService.login.username,
                    lang: this.lang,
                    clientId: this.policy.clientId
                });

                // lettre de renon
                this.policy.policyRisk.forEach(risk => {
                    if (risk.coInsuranceExpirationDate !== 0 && risk.coInsuranceExpirationDate > this.today)  {
                        this.documentsList.push({
                            docNameFr: this.translationObjectFr['renunciationLetter'],
                            docNameNl: this.translationObjectNl['renunciationLetter'],
                            type: 'renon',
                            docCode: '',
                            policyId: this.policy.id,
                            amendmentId: this.policy.amendmentId,
                            user: this.appService.login.username,
                            lang: this.lang,
                            clientId: this.policy.clientId
                        });
                    }

                    // conditions spéciales
                    if (risk.rate.conditionId !== '') {
                        if (this.documentsList.length > 0) {
                            if (!this.documentsList.some(document => (document.docCode === risk.rate.conditionId &&
                                document.docCode !== ''))) {
                                this.documentsList.push({
                                    docNameFr: risk.rate.condition.descriptionFr1 +
                                               risk.rate.condition.descriptionFr2,
                                    docNameNl: risk.rate.condition.descriptionNl1 +
                                               risk.rate.condition.descriptionNl2,
                                    type: 'condition',
                                    docCode: risk.rate.condition.conditionId,
                                    policyId: this.policy.id,
                                    amendmentId: this.policy.amendmentId,
                                    user: this.appService.login.username,
                                    lang: this.lang,
                                    clientId: this.policy.clientId
                                });
                            }
                        } else {
                            this.documentsList.push({
                                docNameFr: risk.rate.condition.descriptionFr1 +
                                           risk.rate.condition.descriptionFr2,
                                docNameNl: risk.rate.condition.descriptionNl1 +
                                           risk.rate.condition.descriptionNl2,
                                type: 'condition',
                                docCode: risk.rate.condition.conditionId,
                                policyId: this.policy.id,
                                amendmentId: this.policy.amendmentId,
                                user: this.appService.login.username,
                                lang: this.lang,
                                clientId: this.policy.clientId
                            });
                        }
                    }
                });

                break;
        }
    }

    downloadDocument(document: any) {
        let doc;
        const lg = document.lang === '1' ? 'F' : 'N';
        if (document.docCode !== '') {
            doc = this.policy.files.filter(file => file.id === lg + document.docCode);
            if (doc.length > 0) {
                this.offerService.getDocument(doc[0].file).subscribe(blobContent => {
                    saveAs(blobContent, doc[0].file );
                }, err => this.appService.handleError(err));
            } else {
                this.appService.handleError({message: 'File not found'});
            }
        } else {
            this.offerService.createDocument(document).subscribe(blobContent => {
                saveAs(blobContent, `${document['docName' + this.appService.getDescriptionLanguage()]}_DAS_${this.policy.name}.pdf` );
            }, err => this.appService.handleError(err));
        }
    }

    downloadAll(document: any) {
        if (document.length > 0) {
            this.offerService.getDocuments(document).subscribe(blobContent => {
                saveAs(blobContent, `AllDocs_DAS_${this.policy.name}.zip` );
            });
        }
    }
}
