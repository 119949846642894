import { Risk } from './risk';

export class Offer {
    id: number;
    lastName: string;
    firstName: string;
    co: string;
    street: string;
    number: string;
    boxNumber: string;
    city: string;
    zip: string;
    language: string;
    phone: string;
    digitalConsent: boolean;
    email: string;
    nationalNumber: string;
    iban: string;
    bic: string;
    bceNumber: string;
    status: number;
    birthDate: Date;
    startDate: Date;
    dueDate: string;
    cashingMode: string;
    cashingModeAS400: string;
    domiciliation: boolean;
    domiciliationAS400: string;
    paymentFrequency: string;
    paymentFrequencySignification: string;
    paymentFrequencyAS400: string;
    commissionRate: string;
    commissionRateAS400: string;
    action: string;
    brokerReference: string;
    returnBlockSending: string;
    documentsPrinting: string;
    documentsSending: string;
    brokerId: number;
    username: string;
    policyNumber: string;
    visibility: string;
    date: string;
    lrcNumber: number;
    actionMailing: boolean;
    PRBQ: string; // total quittance (data from PRIMCALC)
    DEBQ: Date; // période de (data from PRIMCALC)
    FINQ: Date; // à (date d'échéance) (data from PRIMCALC)
    risks: Array<Risk>;
}
