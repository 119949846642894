import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { Simulation } from '../models/simulation';
import { Token } from '../models/token';

@Injectable({
    providedIn: 'root'
})
export class SimulationService {

    constructor(
        private appService: AppService,
        private http?: HttpClient
    ) {
    }

    public getSimulations(): Observable<Array<Simulation>> {
        return this.http.get<Array<Simulation>>(environment.api + `simulations`, this.appService.getOptions());
    }

    public createToken(token: Token): Observable<Token> {
        return this.http.post<Token>(`${environment.api}tokens`, token, this.appService.getOptions());
    }

    public getToken(tokenId: string): Observable<Token> {
        return this.http.get<Token>(`${environment.api}tokens/${tokenId}`, this.appService.getOptions());
    }

    public deleteSimulation(simulation: Simulation): Observable<Simulation> {
        return this.http.delete<Simulation>(`${environment.api}simulations/${simulation.id}`, this.appService.getOptions());
    }
}
