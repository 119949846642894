import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';
import { Policy } from '../models/policy';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Receipt } from '../models/receipt';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(
    private appService: AppService,
    private http?: HttpClient
  ) { }

  public getAllAmendments(id: string): Observable<Array<Policy>> {
    return this.http.get<Array<Policy>>(environment.api + `policies/${id}`, this.appService.getOptions());
  }

  public getPolicy(id: string, amendmentId: string): Observable<Policy> {
    return this.http.get<Policy>(environment.api + `policies/${id}/version/${amendmentId}`, this.appService.getOptions());
  }

  public policyAlreadyExists(obj: object): Observable<Policy> {
    return this.http.post<Policy>(environment.api + `contracts/already-exists`, obj, this.appService.getOptions());
  }

  public getPolicies(): Observable<Array<Policy>> {
    return this.http.get<Array<Policy>>(environment.api + `policies`, this.appService.getOptions());
  }

  public searchPolicies(term: any): Observable<Array<Policy>> {
    return this.http.get<Array<Policy>>(environment.api + `policies/search/${term}`, this.appService.getOptions());
  }

  public getReceipt(id: string, amendmentId: string, clientId: string): Observable<Receipt> {
    return this.http.get<Receipt>(environment.api + `receipts/${id}/${amendmentId}/${clientId}`, this.appService.getOptions());
  }
}
