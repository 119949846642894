<div class="login-header">
    <div class="flex-item">
        <div class="lang">
            <a class="header-button" (click)="appService.setLanguage('fr')" *ngIf="appService.getLanguage()!=='fr'">FR</a>
            <a class="header-button" (click)="appService.setLanguage('nl')" *ngIf="appService.getLanguage()!=='nl'">NL</a>
        </div>
        <a routerLink="/login" routerLinkActive="active"
            title="Terug naar de homepage"
            rel="noreferrer">
            <img alt="DAS" class="logo" src="/assets/img/logo.png" *ngIf="appService.isDas === true"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_fr.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'fr'"/>
            <img alt="Belfius" class="logo" src="/assets/img/belfius_nl.png" *ngIf="appService.isBelfius === true && appService.getLanguage() === 'nl'"/>
            <img alt="AP" class="logo" src="/assets/img/dvv_fr.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'fr'"/>
            <img alt="DVV" class="logo" src="/assets/img/dvv_nl.png" *ngIf="appService.isDvv === true && appService.getLanguage() === 'nl'"/>
        </a>
        <h3>DAS Extranet</h3>
        <p>
            {{'Login to continue' | translate}}
        </p>
    </div>
</div>