import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { OffersListComponent } from './containers/offers-list/offers-list.component';
import { HeaderComponent } from './components/header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './containers/login/login.component';
import { LoginHeaderComponent } from './components/header/login-header.component';
import { ToastrModule } from 'ngx-toastr';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthGuard } from './guards/auth';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { GooglePlacesComponent } from './components/google-places/google-places.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DocumentsDownloadComponent } from './components/documents-download/documents-download.component';
import { SimulationsComponent } from './components/simulations/simulations.component';
import { SimulationsListComponent } from './containers/simulations-list/simulations-list.component';
import { NewSimulationButtonComponent } from './components/new-simulation-button/new-simulation-button.component';
import { RedirectComponent } from './containers/redirect/redirect.component';
import { ClaimsComponent } from './components/claims/claims.component';
import { NewClaimComponent } from './containers/new-claim/new-claim.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { EditClientDataComponent } from './components/edit/edit-client-data.component';
import { EditContractDataComponent } from './components/edit/edit-contract-data.component';
import { EditRiskDataComponent } from './components/edit/edit-risk-data.component';
import { EditBonusCalculationComponent } from './components/edit/edit-bonus-calculation.component';
import { OfferEditComponent } from './containers/offer-edit/offer-edit.component';
import { RiskEditComponent } from './containers/risk-edit/risk-edit.component';
import { PoliciesListComponent } from './containers/policies-list/policies-list.component';
import { OffersComponent } from './components/offers/offers.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { PoliciesViewContractComponent } from './containers/policies-view-contract/policies-view-contract.component';
import { PolicyEditComponent } from './containers/policy-edit/policy-edit.component';

// TODO: condition isBelfius ou DVV il ne faut pas activer les route "risk"
const appRoutes: Routes = [
    { path: 'redirect/:type', component: RedirectComponent},
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'production/offers', component: OffersListComponent, canActivate: [AuthGuard]},
    { path: 'production/offer/create/:step', component: OfferEditComponent, canActivate: [AuthGuard] },
    { path: 'production/offer/edit/:id/:step', component: OfferEditComponent, canActivate: [AuthGuard] },
    { path: 'production/:mode/risk/:action/:id', component: RiskEditComponent, canActivate: [AuthGuard] }, // mode = create
    { path: 'production/:mode/risk/:action/:typeId/:id', component: RiskEditComponent, canActivate: [AuthGuard] }, // mode = edit
    { path: 'simulations/list', component: SimulationsListComponent, canActivate: [AuthGuard]},
    { path: 'production/policies', component: PoliciesListComponent, canActivate: [AuthGuard]},
    { path: 'production/policy/view/:id/:amendmentId', component: PoliciesViewContractComponent, canActivate: [AuthGuard] },
    { path: 'production/policy/edit/:id/:amendmentId/:step', component: PolicyEditComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'claims/create/:step', component: NewClaimComponent, canActivate: [AuthGuard] },
    { path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        PageNotFoundComponent,
        OfferEditComponent,
        EditClientDataComponent,
        EditContractDataComponent,
        EditRiskDataComponent,
        RiskEditComponent,
        EditBonusCalculationComponent,
        PoliciesListComponent,
        OffersListComponent,
        OffersComponent,
        HeaderComponent,
        LoginComponent,
        LoginHeaderComponent,
        FocusInvalidInputDirective,
        GooglePlacesComponent,
        ConfirmComponent,
        DocumentsDownloadComponent,
        SimulationsComponent,
        SimulationsListComponent,
        NewSimulationButtonComponent,
        RedirectComponent,
        ClaimsComponent,
        NewClaimComponent,
        PoliciesComponent,
        PoliciesListComponent,
        PoliciesViewContractComponent,
        AdminPanelComponent,
        PolicyEditComponent
    ],
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false, onSameUrlNavigation: 'reload' } // <-- debugging purposes only
        ),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FontAwesomeModule,
        ToastrModule.forRoot(),
        TextMaskModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
