<div class="white-container">
    <div class="center"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></div>
    <ng-container *ngIf="policy !== null && loading === false">
        <h2>{{'amendments.contract' | translate}} n° {{policy.id}} {{'amendments.for' | translate}} {{policy.name}}</h2>
        <div ngbDropdown class="d-inline-block actions">
            <button class="btn btn-outline-primary" id="dropdownActions" ngbDropdownToggle>
                <span *ngIf="loadAction" class="spinner-border spinner-border-sm mr-1"></span>
                Actions
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownActions">
                <button ngbDropdownItem>
                    <a class="header-button" (click)="amendmentInit()" title="{{'amendments.modify' | translate}}">
                        <fa-icon [icon]="faFileSignature"></fa-icon>
                        <span>{{'amendments.modify' | translate}}</span>
                    </a>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faBook"></fa-icon>
                    <span>{{'amendments.documents' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faHandHoldingUsd"></fa-icon>
                    <span>{{'amendments.receipt' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faExchangeAlt"></fa-icon>
                    <span>{{'amendments.bloc' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faAt"></fa-icon>
                    <span>{{'amendments.email' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faWallet"></fa-icon>
                    <span>{{'amendments.mailing' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faAsterisk"></fa-icon>
                    <span>{{'amendments.clauses' | translate}}</span>
                </button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem>
                    <fa-icon [icon]="faBan" class="caution"></fa-icon>
                    <span class="caution">{{'amendments.cancelContract' | translate}}</span>
                </button>
                <button ngbDropdownItem>
                    <fa-icon class="specificMargin caution" [icon]="faFileContract"></fa-icon>
                    <fa-icon class="top" [icon]="faSlash"></fa-icon>
                    <span class="caution">{{'amendments.terminateContract' | translate}}</span>
                </button>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4>{{'amendments.contractDetails' | translate}}</h4>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownHistory" ngbDropdownToggle>{{currentAmendment}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownHistory">
                        <button ngbDropdownItem *ngFor="let history of historyList">
                            <a [routerLink]="history.routerLink">{{history.display}}</a>
                        </button>
                    </div>
                </div>
                <div class="row details">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.address' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.client.street}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.boxnumber' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.client.number}} {{policy.client.boxNumber === '' ? '' : ' / ' + policy.client.boxNumber}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.locality' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.client.zip}} / {{policy.client.city}}
                            </div>
                        </div>
                        <div class="row" *ngIf="policy.client.co !== ''">
                            <div class="col-6">
                                {{'amendments._contractDetails.co' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.client.co}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments.state' | translate}} :
                            </div>
                            <div class="col-6">
                                {{'amendments.code.' + policy.amendmentCodeSignification | translate}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <!-- emplacement message "état" complémentaire -->
                            </div>
                            <div class="col-6">
                                {{policy.cancellationCodeSignification === '' || policy.cancellationCodeSignification === 'checkReceipt' ? 
                                    '' : 'amendments.cancellationCode.' + policy.cancellationCodeSignification | translate}} {{receiptDueDate | date:'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.phone' | translate}} :
                            </div>
                            <div class="col-6">
                                {{office.phone}}
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.deadline' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.expirationDate | date:'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.creationDate' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.creationDate | date:'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.startDate' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.startDate | date:'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.frequency' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.paymentFrequencySignification === '' ? '' : 'amendments.frequency.' + policy.paymentFrequencySignification | translate}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.domiciliation' | translate}} :
                            </div>
                            <div class="col-6">
                                {{domStatus == null ? '' : 'amendments.domiciliation.' + domStatus | translate}}
                            </div>
                        </div>
                        <div class="row" *ngIf="domStatus !== 'NO'">
                            <div class="col-6">
                                {{'amendments._contractDetails.number' | translate}} :
                            </div>
                            <div class="col-6">
                                {{domPolicy?.communicationToPayer}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.cashing' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.cashingMode === '' ? '' : 'product.' + policy.cashingMode | translate}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                {{'amendments._contractDetails.commissionRate' | translate}} :
                            </div>
                            <div class="col-6">
                                {{policy.commissionRate === '' ? '' : 'product.' + policy.commissionRate | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{'product.effect' | translate}}</th>
                    <th scope="col">{{'product.risk' | translate}}</th>
                    <th scope="col">{{'product.plateNumber' | translate}}</th>
                    <th scope="col">{{'amendments.state' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let risk of policy.policyRisk; index as i">
                    <td>
                        {{ risk.startDate | date:'dd/MM/yyyy' }}
                    </td>
                    <td>{{ risk.rate['description' + appService.getDescriptionLanguage()] }}</td>
                    <td>{{ risk.plateNumber }}</td>
                    <td>{{ 'amendments.code.' + risk.amendmentCode | translate }}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>