import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { Offer } from 'src/app/models/offer';
import { AppService } from 'src/app/services/app.service';
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-offers-list',
    templateUrl: './offers-list.component.html',
    styleUrls: ['./offers-list.component.scss'],
    providers: [OfferService]
})
export class OffersListComponent implements OnInit {
    offers: Array<Offer>;
    offersToDisplay: Array<Offer>;
    faTrashAlt = faTrashAlt;
    faPencilAlt = faPencilAlt;
    onlyOffers = true;
    colleaguesOffers = false;
    @ViewChild('confirm', {static: true}) confirmModal: TemplateRef<any>;
    constructor(
        public appService: AppService,
        private offerService: OfferService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.getOffers();
    }

    getOffers() {
        this.offerService.getOffers().subscribe(resp => {
            this.offers = resp;
            this.offersToDisplay = this.offers;
            this.checkOffersToDisplay(this.onlyOffers, this.colleaguesOffers);
        }, error => {
            this.appService.handleError(error);
        });
    }

    checkOffersToDisplay(cbOnlyOffers, cbColleaguesOffers) {
        this.offersToDisplay = this.offers;

        if (cbOnlyOffers) {
            this.offersToDisplay = this.offersToDisplay.filter(off => off.policyNumber === '');
        }
        if (!cbColleaguesOffers) {
            this.offersToDisplay = this.offersToDisplay.filter(off => off.username === this.appService.login.username);
        }
    }

    deleteOffer(id) {
        this.modalService.open(this.confirmModal).result.then((result) => {
            if (result === 'confirm') {
                this.offerService.deleteOffer(id).subscribe(success => {
                    this.appService.handleSuccess(success);
                    this.getOffers();
                }, error => {
                    this.appService.handleError(error);
                });
            }
          }, (reason) => {
        });
    }
}
