import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { faTrashAlt, faPencilAlt, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { Offer } from 'src/app/models/offer';
import { OfferService } from 'src/app/services/offer.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Risk } from 'src/app/models/risk';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: 'app-edit-risk-data',
    templateUrl: './edit-risk-data.component.html',
    styleUrls: ['./edit-risk-data.component.scss']
})
export class EditRiskDataComponent implements OnInit, OnChanges {
    @Input() offer: Offer;
    @Input() mode: string;
    @Input() calcul: boolean;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    faTrashAlt = faTrashAlt;
    faPencilAlt = faPencilAlt;
    faCartPlus = faCartPlus;
    newTypeRiskVehicleNumber;
    newTypeRiskFamilyNumber;
    newTypeRiskBuildingNumber;
    environment = environment;
    loading = false;
    constructor(
        public translate: TranslateService,
        private offerService: OfferService,
        private router: Router,
        public appService: AppService
    ) { }

    ngOnInit() {
        if (!environment.isDas) {
            this.priceCalculation();
        } else {
            if (this.calcul === true) {
                this.checkAllRisk();
            }
        }
    }

    ngOnChanges() {
        if (this.offer.risks !== undefined) {
            this.newTypeRiskVehicleNumber = '4' + ('0' +
                Number(this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '4').length + 1)).slice(-2);
            this.newTypeRiskBuildingNumber = '3' + ('0' +
                Number(this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '3').length + 1)).slice(-2);
            this.newTypeRiskFamilyNumber = '2' + ('0' +
                Number(this.offer.risks.filter(risk => risk.type.toString().slice(0, 1) === '2').length + 1)).slice(-2);
        }
    }

    checkAllRisk() {
        // 628 = all risk avec voiture gratos / 627 = all risk classique / 029 = all risk conducteur
        if (this.offer.risks.some(risk => risk.riskCode === 628)) {
            // si l'offre ne contient pas des risques type véhicules (entre 401 et 410 et <> 627 628 029)
            if (!this.offer.risks.some(risk =>
                risk.type > 400 && risk.type < 411 && risk.riskCode !== 627 && risk.riskCode !== 628 && risk.riskCode !== 29)) {
                // on remplace la 628 par 627
                const risk628 = this.offer.risks.filter(risk => risk.type === 628)[0];
                risk628.type = 627;
                this.offerService.updateRisk(risk628.type, this.offer.id, risk628).subscribe(res => {
                    this.offer.risks.filter(risk => risk.type === 628)[0] = res;
                    this.priceCalculation();
                });
            } else {
                this.priceCalculation();
            }
        } else {
            if (this.offer.risks.some(risk => risk.riskCode === 627)) {
                // si l'offre contient des risques type véhicules (entre 401 et 410 et <> 627 628 029)
                if (this.offer.risks.some(risk =>
                    risk.type > 400 && risk.type < 411 && risk.riskCode !== 627 && risk.riskCode !== 628 && risk.riskCode !== 29)) {
                    // on remplace la 627 par 628
                    const risk628 = this.offer.risks.filter(risk => risk.type === 627)[0];
                    risk628.type = 628;
                    this.offerService.updateRisk(risk628.type, this.offer.id, risk628).subscribe(res => {
                        this.offer.risks.filter(risk => risk.type === 627)[0] = res;
                        this.priceCalculation();
                    });
                } else {
                    this.priceCalculation();
                }
            } else {
                this.priceCalculation();
            }
        }
    }

    priceCalculation() {
        this.loading = true;
        this.offerService.priceCalculation(this.offer).subscribe(res => {
            this.loading = false;
            this.offer.PRBQ = res.PRBQ;
            this.offer.DEBQ = res.DEBQ;
            this.offer.FINQ = res.FINQ;
            if (res.COMBERR === '1') {
                if (this.offer.domiciliationAS400 === '12') {
                    if (res.PRB < 116) {
                        this.translate.get('errors.primcalcPRB').subscribe((errorMsg: string) => {
                            alert(errorMsg);
                        });
                    }
                }
            } else {
                if (res.PRBQ === '0') {
                    this.translate.get('errors.primcalcPRBQ').subscribe((errorMsg: string) => {
                        alert(errorMsg);
                    });
                } else {
                    this.update.emit(this.offer);
                }
            }
        }, (error) => {
            this.translate.get('errors.primcalc').subscribe((errorMsg: string) => {
                this.appService.handleError(errorMsg);
            });
        });
    }

    deleteRisk(risk: Risk) {
        this.offerService.deleteRisk(risk.offerId, risk.type)
            .subscribe(resp => {
                const index = this.offer.risks.findIndex(r => {
                    if (r.offerId === risk.offerId && r.type === risk.type) {
                        return true;
                    }
                });
                this.offer.risks.splice(index, 1);
                this.appService.handleSuccess('Risk deleted');
            }, err => this.appService.handleError(err));
    }
}
